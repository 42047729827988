@import 'common';

$icon-color: #a2acbb;
$selected-color: rgba($primary, .075);

.show > .btn-primary.dropdown-toggle {
  color: $icon-color;
  background-color: transparent;
  border-color: transparent;
}

.card-menu {
  border: none !important;
  height: min-content !important;
}

.dropdown-toggle:hover {
  background-color: $selected-color;
}

.remove-arrow:after {
  display: none;
}

.dropdown-menu {
  border-color: lighten($primary, 25%);
}

.dropdown-item:hover {
  background-color: $selected-color;
}