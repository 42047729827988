@import '../common';

$past-timeline-width: 95%;
$timeline-line-color: lighten($dark-purple, 30);

.scroll-spinner {
  width: 100%;
  display: flex;
  justify-content: center;
  color: $ocean-green;
  padding: 0.5em;
  .spinner-border {
    width: 1.5em;
    height: 1.5em;
  }
}

.timeline-page {
  display: flex;
  flex-direction: column;
  padding-bottom: 2em;

  &__filters {
    display: flex;
    margin-bottom: .5em;
    margin-top: .5em;
    align-items: center;

    .search-by-account-input {
      padding: 0.5em 0.5em 0.5em 1em;
      margin: 0 1em 0 0 ;
      height: 3em;
    }

    .ordo-multi-select {
      margin-right: 1em;
    }

    .ordo-searchable-dropdown-container {
      width: 15em;
      margin: 0 1em 0 0;
    }

    .sorting-amount-filter {
      display: flex;
      align-items: center;
      cursor: pointer;

      .sort-icon {
        height: 2em;
        width: 1.5em;
        color: $gray-900;
        margin-right: .5em;
        transform: rotate(90deg);
      }
    }
  }
}

.accounts-timeline {
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid lighten($table-separator-color,15);
  border-radius: $border-radius;
}

.timeline-header {
  display: flex;
  width: 100%;
  height: 3em;
  border: 1px solid transparent;

  .account-name {
    font-weight: 600;
    width: 20%;
    max-width: 20%;
    min-width: 20%;
    padding: 1em 1em 0;
    color: $gray-700;
  }

  .account-timeline {
    width: 75%;
    max-width: 75%;
    display: flex;
    justify-content: space-between;

    .past-events {
      width: 95%;
      display: flex;
    }

    .timeline-period {
      display: flex;
      justify-content: center;
      padding: 1em 0 0;
      font-weight: bold;
      color: $gray-700;
      //border-right: 1px solid $section-break-color;
      position: relative;
      .vertical-line{
        position: absolute;
        right: 0;
        width: 1px;
        border-right: 1px solid $timeline-line-color;
        height: 100%;
        z-index: -1;

        &.indicator {
          //bottom: -4px;
          height: 40%;
        }
      }

    }

  }

  .indicators {
    display: flex;
    justify-content: space-between;
    color: $dark-purple;
    font-weight: bold;
    font-size: 0.6em;
    width: 5%;
    padding: 1.5em 0 0;
    position: relative;

    .indicator {
      position: absolute;
      width: min-content;
      font-size: 1.2em;
      top: 20%;

      &.left {
        left: -35%
      }

      &.right {
        right: -60%
      }
    }

    .vertical-line {
      position: absolute;
      width: 1px;
      border-left: 1px solid $timeline-line-color;
      //z-index: -1;
      bottom: -4px;
      height: 40%;

      &.right {
        right: 0;
      }
      &.left {
        left: -1px;
      }
    }
  }

}

.account-row {
  display: flex;
  width: 100%;
  //height: 3em;

  &:not(:last-child) {
    border-bottom: 1px solid $table-separator-color;
  }

  .account-name {
    color: $dark-purple;
    font-weight: 600;
    width: 20%;
    max-width: 20%;
    min-width: 20%;
    padding: 1em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    span {
      cursor: pointer;
      &:hover {
        text-decoration: underline $primary;
      }
    }
  }

  .account-timeline {
    width: 75%;
    max-width: 75%;
    display: flex;

    .past-events {
      display: flex;
      width: 95%;
      position: relative;
      .background-line {
        position: absolute;
        width: 100%;
        top: 50%;
        border-top: 2px solid $timeline-line-color;
      }
      .timeline-period {
        display: flex;
        border-right: 1px solid $timeline-line-color;
        padding: 1.25em 0;
      }
    }


    .upcoming {
      border-right: 1px solid $timeline-line-color;
      width: 5%;
      padding: 1.25em 0;
      position: relative;
      .line-future {
        position: absolute;
        width: 100%;
        top: 50%;
        border-top: 2px dashed $timeline-line-color;
      }
    }


    .content {
      display: flex;
      width: 100%;
      background-color: transparent;
      //margin-top: 1em;
      height: 100%;
      z-index: 1;
      flex-direction: column;
      $item-width: 1.85em;

      .time-slot {
        position: relative;

        .timeline-item {
          background-color: $white;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: x-small;
          font-weight: bold;
          height: $item-width;
          width: $item-width;
          border-radius: 100px;
          cursor: pointer;
          position: absolute;
          right: calc(-#{$item-width } / 2);
          z-index: 1;

          &.note {
            color: $note-color;
            border: 1px solid $note-color;
          }

          &.activity, &.external-activity {
            color: $activity-color;
            border: 1px solid $activity-color;
            &.incomplete {
              color: $incomplete-activity-color;
              border: 1px solid $incomplete-activity-color;
            }
          }

          &.order {
            color: $order-color;
            border: 1px solid $order-color;

            svg {
              font-size: 1.25em;
            }
          }
        }

        &.showing-info {

          .timeline-item {
            color: white;
            &.note {
              background-color: $note-color;
            }

            &.activity, &.external-activity {
              background-color: $activity-color;

              &.incomplete {
                color: white;
                background-color: $incomplete-activity-color;
              }
            }

            &.order {
              background-color: $order-color;
            }
          }
        }
        &.stacked {
          .timeline-item {
            border-color: $gray-600;
          }
        }
      }



    }
  }

  .options {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5%;
  }
}

.info-popup {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 500px;
  height: fit-content;
  max-height: 270px;
  border-radius: $border-radius;
  z-index: 2;
  background-color: white;
  border: 1px solid whitesmoke;

  &__included-dates {
    background-color: whitesmoke;
    display: flex;
    justify-content: center;
    color: $dark-purple;
    font-weight: bold;
    font-size: 0.85em;
    border-top-right-radius: $border-radius;
    border-top-left-radius: $border-radius;

  }

  &__body {
    overflow-y: scroll;
    .account-activity-card:not(:last-child) {
      border-bottom: 1px solid $table-separator-color;
    }

    .account-activity-card {
      .gmail-icon {
        height: 1em;
        width: auto;
        margin-right: 0.35em;
      }
    }
  }
}

.order-item-container {
  .product-info{
    font-size: x-small;

    .original-price {
      font-size: x-small;
    }

    .title {
      font-size: xx-small !important;
    }
  }
}

.order-items {
  margin: .5em 0 1em;
}



