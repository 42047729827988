//
// Laptop frame
//


.frame-laptop {
    position: relative;

    .frame-inner {
        overflow: hidden;
        position: absolute;
        width: 78%;
        height: 74%;
        left: 0;
        top: 0;
        background-color: #111;
        margin: 7.5% 11% 0 11%;
        border-radius: .25rem;
        box-shadow: inset 0px 2px 5px 0px rgba(0,0,0,.2);
    }
}
