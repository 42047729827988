//
// Section floating icons
//


.section-floating-icons {

    --icon-size: 5rem;
    --icon-sm-size: 3.75rem;
    --gutter: 7rem;

    .icons-container {
        position: relative;
        max-width: 100%;
        height: 360px;
        margin: 0 auto;

        span {
            position: absolute;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background: $card-bg;
            z-index: 1;
            transform: translate(-50%, -50%);
            @include border-radius(50%);
            @include box-shadow($box-shadow);
            @include transition($transition-cubic-bezier);

            &.icon {
                width: 5rem;
                height: 5rem;

                i {
                    font-size: 2.3em;
                }

            }

            &.icon-sm {
                width: 3.75rem;
                height: 3.75rem;

                i {
                    font-size: 1.5em;
                }

            }

            &:nth-child(1) {
                font-size: 42px;
                color: theme-color("warning");
                z-index: 2;
            }

            img {
                max-width: 100%;
            }
        }

        span {
            opacity: 1;

            &:nth-child(1) {
                left: 50%;
                top: 50%;
                font-size: 42px;
                color: theme-color("info");
            }

            &:nth-child(2) {
                left: calc(50% + (7rem * 1.7));
                top: 50%;
            }

            &:nth-child(3) {
                left: calc(50% + 7rem);
                top: calc(50% + 7rem);
            }

            &:nth-child(4) {
                left: calc(50% + 7rem);
                top: calc(50% - 7rem);
            }

            &:nth-child(5) {
                left: calc(50% + (7rem * 4));
                top: 50%;
            }

            &:nth-child(6) {
                left: calc(50% + (7rem * 2.7));
                top: calc(50% + (7rem * 1.5));
            }

            &:nth-child(7) {
                left: calc(50% + (7rem * 2.7));
                top: calc(50% - (7rem * 1.5));
            }

            &:nth-child(8) {
                left: calc(50% - (7rem * 1.7));
                top: 50%;
            }

            &:nth-child(9) {
                left: calc(50% - 7rem);
                top: calc(50% + 7rem);
            }

            &:nth-child(10) {
                left: calc(50% - 7rem);
                top: calc(50% - 7rem);
            }

            &:nth-child(11) {
                left: calc(50% - (7rem * 4));
                top: 50%;
            }

            &:nth-child(12) {
                left: calc(50% - (7rem * 2.7));
                top: calc(50% + (7rem * 1.5));
            }

            &:nth-child(13) {
                left: calc(50% - (7rem * 2.7));
                top: calc(50% - (7rem * 1.5));
            }

        }
    }
}
