@import './common';

.metrc-product-link-modal {

  .metrc-integration-header-text {
    margin-left: 2em;
    font-size: small;
  }

  .metrc-product-link-license-row{
    color: black;
    width: 66%;
    margin: auto;

    .metrc-product-link-license {
      margin-left: 25px;
      display: inline-block;
    }

    .metrc-product-link-license-number {
      font-weight: 700;
      margin-bottom: -5px;
    }
  }

  .metrc-select-item-row {
    margin-top: 10px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    display: block;
    color: black;
    border-bottom: 1px solid $gray-200;
    &:hover {
      cursor: pointer;
      background-color: whitesmoke;
    }

    &.selected-item {
      background-color: $gray-200;
    }

    .metrc-item-quantity {
      float: right;
      margin-right: 4em;
    }
  }

  .metrc-item-description {
    width: 75%;
    display: inline-block;
  }

  .metrc-item-quantity {
    display: inline-block;
  }


  hr {
    color: darkgray;
    margin-top: 2px;
    margin-bottom: 2px;
  }
}