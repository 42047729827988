@import '../../purpose/variables';
@import '../common';

.ordo-table__cell {
  line-height: 1.3;
}

.ordo-product-preview {
  display: flex;
  flex-direction: row;
  margin-top: 0.5em;

  &__image {
    width: 4em;
    height: 4em;
    border-radius: 10px;
  }

  &__info {
    width: 70%;
    max-width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: .5em;

    &__name {
      white-space: normal;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-weight: 700;
    }
  }
}

.cases-price-unit {
  font-size: xx-small;
}
