@import '../purpose/variables';
@import '../ordo/common';


$radius: 15px;
$arrow-size: 2em;
$zindex: 1;

.number-input, number-input:focus {
  padding-left: 1em;
  padding-right: $arrow-size;
  width: 6em;
  height: $arrow-size;
  border: 2px $primary solid;
  border-radius: $radius;
}

.input-label {
  line-height: 1;
  margin-left: 1em;
  margin-bottom: -0.5em;
  padding:0 0.2em;
  max-width: min-content;
  font-weight: 600;
  color: $primary;
  background-color: $white;
  z-index: $zindex;
  font-size: xx-small;
}


.input-icons {
  display: flex;
  z-index: $zindex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: -$arrow-size;
  width: $arrow-size;
  height: $arrow-size;
  color: $white;
  background-color: $primary;
  border-radius: $radius;

  .input-icon {
    height: 0.75em;
    width:100%;
  }

  .increase-amount {
    cursor: pointer;

    &:hover {
      border-top-right-radius: $radius;
      border-top-left-radius: $radius;
      background-color: darken($primary, 10%);
    }
  }

  .decrease-amount {
    cursor: pointer;
    margin-top: -0.1em;
    &:hover {
      border-bottom-right-radius: $radius;
      border-bottom-left-radius: $radius;
      background-color: darken($primary, 10%);
    }
  }
}

.ordo-number-input {
  position: relative;

  .invalid-feedback {
    display: block;
    position: absolute;
    font-size: $font-size-tiny;
    line-height: 1;
    height: 0.5em;
    top: 4em;
    padding-left: 1em;
    margin-bottom: 0;
  }
}


.is-invalid {
  .number-input, number-input:focus {
    border: 2px $danger solid;
  }
  .input-label {
    color: $danger;
  }
  .input-icons {
    color: $white;
    background-color: $danger;
    border: 2px $danger solid;
    .increase-amount {
      width: 100%;
      margin-top: -0.1em;
      &:hover {
        background-color: lighten($danger, 5%);
      }
    }
    .decrease-amount {
      width: 100%;
      &:hover {
        background-color: lighten($danger, 5%);

      }
    }
  }
}

/* override default behaviour of the input, like the arrows and the outline when focus */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input:focus {
  outline:none;
}
input {
  -moz-appearance:textfield !important;
}
