@import '../../purpose/variables';
@import '../../custom/variables';
@import '../common';

.contacts-page-filters {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.contacts-list {
  margin-top: 1em;
  padding: 1em;
  border-radius: $border-radius;
}

.contacts-list-headers {
  width: 100%;
  height: 2em;
  display: flex;
  font-weight: bold;
  color: black;

  .account-name {
    width: 20%
  }

  .contact-name {
    width: 19%
  }

  .email {
    width: 24%
  }

  .phone {
    width: 14%
  }

  .role {
    width: 19%
  }

  .edit-contact-header, .delete-contact-header {
    width: 2%
  }
}

.contact-item {
  height: 4em;
  display: flex;
  align-items: center;
  border-top: 0.5px solid #C0CCDA;
  width: 100%;

  input {
    width: 97%;
    padding: 0.2em 0.3em;
    margin: auto;
  }

  label {
    margin: 0;
  }

  .contact-name {
    width: 19%
  }

  .contact-email {
    display: flex;
    align-items: center;
    width: 24%;
  }

  .contact-phone {
    width: 14%;
    display: flex;
    align-items: center;
  }

  .contact-account-name {
    width: 20%;
    color: $dark-purple;
    font-weight: 600;

    span:hover {
      cursor: pointer;
      text-decoration: underline $primary;
    }

    .ordo-dropdown {

      button {
        border-color: $gray-400;
        height: 2.25em !important;
      }

      button:hover {
        background-color: white !important;
        border-color: $gray-400;

        svg {
          color: $gray-700;
        }
      }
    }

  }

  .contact-title {
    width: 19%;
  }

  .contact-edit-button {
    width: 2%;
    color: $primary;
    cursor: pointer
  }
}

.contact-form-account-selection {
  margin-bottom: 1rem;

  button {
    border-color: $gray-400;
  }

  button:hover {
    background-color: white !important;
    border-color: $gray-400;

    svg {
      color: $gray-700;
    }
  }
}

.cancel-contact-edition {
  color: $pastel-red;
}

.contact-item-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 .5em;
}