@import '../../purpose/variables';
@import '../../ordo/common';

$batch-id-width: 12em;

.fulfillment-location-title {
  color: black;
  font-weight: bold;
  margin-bottom: .75rem;
}

.fulfillment-location-container {
  .error-message {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #FF5630;
  }

  .ordo-searchable-dropdown-container {
    margin-bottom: 0;
  }
}

.batches-container {
  .batch-info {
    display: flex;
    align-items: center;
    margin-bottom: 0.75em;
    justify-content: space-between;

    &__id-and-distributor {
      display: flex;
      flex-direction: column;
      font-size: $font-size-sm;
      line-height: 1.3;

      .batch-id {
        @include text-ellipsis($batch-id-width);
        min-width: $batch-id-width;
        color: $gray-900;
        font-weight: bold;
      }

      .distributor {
        @include text-ellipsis($batch-id-width);
        min-width: $batch-id-width;
        width: 100%;
      }
    }

    &__units-and-id {
      display: flex;
      flex-direction: row;
      align-items: center;

        &__units-and-cases {
          display: flex;
          align-items: center;
          flex-direction: column;
          margin-right: 2em;
          max-width: 3em;

          .total-units {
            font-size: 0.7rem;
            line-height: 1;
          }

          .total-cases {
            font-size: 0.5rem;
          }
        }
    }

    .ordo-input {
      width: 3em;
    }

    label {
      margin-bottom: 0;
    }

    input {
      text-align: center;
      font-size: $font-size-sm !important;
      padding: 0 !important;
      color: $gray-900;
      border-color: transparent;
    }

    input:focus {
      border: solid 2px $primary;
    }

    .coa-button {
      height: 10%;
      border-radius: 7px;
      border-color: transparent;
      background: $primary;
      color: white;
      width: fit-content;
      line-height: 1;
      font-size: $font-size-sm;
    }
  }

  .batch-info > * {
    width: 20%;
    margin-right: 0.5em;
  }

  .add-batch-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .btn {
      padding-left: 0;
      padding-right: 0;
    }
  }


  .batch-row-buttons {
    display: flex;
    justify-content: flex-end;
    margin-right: 1em;

    .delete-batch {
      margin-left: 8px;
    }
  }
}

.add-batch-text {
  font-weight: 700;
  font-size: $font-size-sm;
}

.batch-button-size {
  font-size: .75rem;
}

.thc-and-cbd-container {
  display: flex;
}

.advertised-percentage {
  display: flex;
  align-items: center;
  padding-right: .75em;
  .icon {
    margin-left: .5em;
    margin-top: .8em;
    font-size: small;
    color: $primary
  }
}

.coa-document-section {
  display: flex;
  flex-direction: row;
  align-items: center;

  .icon {
    margin-left: .5em;
    color: $pastel-red;
    font-size: 1.3em;

    &:hover {
      cursor: pointer;
    }
  }
}

.packaged-date-container {
  display: flex;
  align-items: center;

  .icon {
    margin-left: .5em;
    color: $pastel-red;
    font-size: 1.3em;

    &:hover {
      cursor: pointer;
    }
  }
}

.packaged-date-container > * .selected-day-info{
  padding: 0 !important;
}