@import '../common';
@import '../../custom/styles';

.nabis-integration {
  @include ordo-card;
  flex-direction: row;
  justify-content: space-between;
  padding: .5em 1em;
  margin: .5em 0;
  color: black;
  width: 100%;

  .nabis-logo-and-api-key {
    display: flex;
    width: 48%;

    .nabis-logo {
      margin: .5em;

      .nabis-icon {
        height: 2em;
      }
    }

    .nabis-api-key-information {
      display: flex;
      flex-direction: column;
      margin: 0 .5em;
      width: 45%;

      .title {
        font-weight: bold;
        margin-bottom: -0.5em; //has to do this this way to match the mockups. TODO: See if there's a better way
      }

      .key {
        height: 1.7rem;
        font-size: $font-size-base;
      }
    }
  }

  //.nabis-action-buttons {
  //  height: 100%;
  //  display: flex;
  //  padding-bottom: 0.5em;
  //  flex-direction: row-reverse;
  //}

  .delete-nabis-integration {
    color: $primary;
    margin: .5em;
    cursor: pointer;
  }

  .enabled-licenses {
    display: flex;
    flex-direction: column;
    width: 40%;
    .title {
      font-weight: bold;
      white-space: nowrap;
      margin-bottom: -0.3em; //has to do this this way to match the mockups. TODO: See if there's a better way
    }

    .licenses {
      height: 1.7rem;
      overflow: hidden;
      font-size: $font-size-extra-small;
    }
  }
}
