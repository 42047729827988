@import '../purpose/variables';
@import '../custom/styles';
@import 'common';

.sidebar-container {
  width: $sidebar-width;
  min-width: $sidebar-min-width;
  .expanded-sidebar {
    @include ordo-shadow;
    background-color: white;
    position: absolute;
    height: 100%;
    width: max-content;
    padding-right: 1.5em;
    z-index: $Z_INDEX_MAX;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    display: flex;
    flex-direction: column;

    .menu-toggle {
      color: $primary;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .sidebar-btn {
      text-align: center;
      font-size: 1rem;
      padding: .5em;
      width: $sidebar-width;
      height: $sidebar-button-height;
    }

    .extended-button {
      display: flex;
      align-items: baseline;
      color: $gray-700;

      &:hover {
        color: $primary-darkest;
      }

      &.current-page {
        color: $primary;
        &:hover {
          color: $primary-darkest;
        }

        .sidebar-btn {
          color: $primary
        }

        .button-label {
          font-weight: bold;
        }
      }
    }

    @keyframes slidein {
      from {transform: translateX(-100%);}
      to {transform: translateX(0);}
    }
    @keyframes slideout {
      from {transform: translateX(0);}
      to {transform: translateX(-100%);}
    }

    .sidebar-header {
      display: flex;
      align-items: center;
    }
  }

  .sidebar {
    display: flex;
    flex-direction: column;
    position: fixed;
  }

}

.sidebar {
  .sidebar-btn {
    color: $gray-700;
    text-align: center;
    font-size: 1rem;
    padding: .5em;
    width: $sidebar-width;
    height: $sidebar-button-height;
  }

  .sidebar-btn:hover {
    color: $primary-darkest;
  }

  .current-page {
    color: $primary;
  }


  .menu-toggle {
    color: $primary;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
