@import 'common';

.clickable-tag {
  border: 1px solid $primary;
  color: $primary;
  padding: 0 .5em;
  border-radius: $border-radius;

  .remove-icon {
    margin-left: 1em;
    cursor: pointer;
  }
}