@import '../../purpose/variables';
@import '../../custom/variables';
@import 'src/scss/ordo/common';

.organization-card, .pending-invitation-card {
  @include ordo-shadow;
  padding: 2em;
  margin-right: 8px;
  min-height: 24.5em;
  width: 30%;
  border-radius: $border-radius;

  &__upper-section {
    display: flex;
    justify-content: space-between;

    .dropdown {
      button {
        margin: 0;
      }
    }

    &__title {
      font-size: $font-size-lg;
      font-weight: bold;
      color: $primary;
    }
  }

  &__body {
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__user-section {
      width: 100%;
      margin-bottom: 1em;
    }

    .brands-title {
      width: 100%;
      margin-bottom: .5em;
    }

    &__brands-container, &__invitations-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      min-height: 6em;

      .brands {
        display: flex;
        width: 100%;
        justify-content: space-around;

        .brand {
          img {
            height: 4em;
            width: 4em;
            border-radius: 5px;
          }
        }
      }

      .invitation-buttons {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: 7.9em;
        justify-content: space-around;

        button {
          width: 100%;
        }
      }

      .no-brands-added {
        width: 100%;
        height: 6em;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .more-brands-text {
        margin-top: .5em;
      }
    }

    &__invitations-container {
      margin-top: 1em;
    }

    &__owner_title {
      font-size: $font-size-base;
      font-weight: bold;
    }

    &__pending-invite {
      font-size: $font-size-base;
      font-weight: bold;
      color: $gray-700;
    }

    &__owner_name {
      font-size: $font-size-lg;
    }

    &__owner_email {
      font-size: $font-size-base;
    }
  }

  .organization-status {
    padding: .5em 0;
  }
}

.organization-card:hover {
  cursor: pointer;
  background-color: #F8FAFD;
}

@media (max-width: $mobile-breakpoint) {
  .organization-card {
    width: 100%;
  }
}
.selected {
  border: 2px solid $primary;
}
