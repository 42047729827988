@import '../../purpose/variables';
@import '../../custom/variables';
@import '../common';

$colored-border: 3px solid $primary;

.order-history-page {
  height: 100%;
}

.order-history-page-orders-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 1em;
  background-color: white;
  border-radius: $border-radius;
  padding: 1em;

  .order-history-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1em;

    .pagination-buttons-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .pagination-buttons {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;

        &.collapsed {
          width: 100%;
        }

        .current-page-input {
          width: 6em;
          justify-content: center;
          display: flex;
          align-items: center;
          font-size: 1.1em;

          input {
            height: 10%;
            width: 40%;
            margin-right: .5em;
            text-align: center;
            border: 1.5px $primary solid;
            border-radius: 4px;
          }
        }

        .move-to-page-container {
          color: $primary;
          margin-left: .8em;
          display: flex;
          align-items: center;
          font-size: 1.1em;
          cursor: pointer;

          &.disabled {
            cursor: auto;
            color: $gray-700
          }

          &.back {
            margin: 0 .8em 0 0;
          }
        }
      }
    }
  }
}

.order-history-page-order-filters {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .order-history-search-input-order-id {
    width: 25%;
    margin-right: 2em;
    padding-bottom: .4em;

    label { margin-bottom: 0 !important;}

    input {
      width: 100%;
      height: 3.15em !important;
      border-radius: 2em !important;
      box-shadow: 0 0 0.4em 0.1em #e9ecef !important;
      color: #aabacd !important;

      &:focus {
        border-color: transparent !important;
        color: #aabacd !important;
      }
    }

    input::placeholder {
      color: #aabacd;
    }
  }

  .search-order-input {
    margin-right: 2em;
    width: 10%;
    min-width: 12em;
    margin-bottom: 0.4em;
    border-radius: 2em;
    box-shadow: 0 0 0.4em 0.1em $brand-logo-border;
    border: 0.1em solid transparent;
    font-weight: initial;
    font-size: 0.9em;
    padding: 0.54rem 1rem;
    color: gray;
  }
}

.order-history-orders-list {
  width: 100%;
}

.no-orders-content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
  color: $gray-900;
}
.greyed-out {
  color: $gray-700;
}

.regular-color {
  color: $black;
}

.order-history-order-item-container {
  display: flex;
  flex-direction: column;
  margin: 0.5em 0;
  border-radius: $border-radius;

  &.expanded {
    border: $colored-border;

    .tracked-order-action-buttons {
      display: flex;
      justify-content: flex-end;
      padding: 1em;

      &__cancel {
        margin-right: 1.5em;
      }

      &__view-invoice {
        margin-right: 1.5em;
      }
    }
  }

  &__details {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    &__line_items {
      display: flex;
      flex-direction: row;
      height: 50%;
      width: 100%;
      max-height: 18em;
      overflow-y: auto;
    }

    &__summary {
      display: flex;
      flex-direction: row;
      height: 50%;
      width: 100%;
      margin-top: 0.5em;
      border-radius: 0 0 $border-radius;

      &__delivery {
        border-radius: 0 0 0 $border-radius;
        border-right: $colored-border;
        width: 70%;
      }

      &__payment {
        border-radius: 0 0 $border-radius 0;
        width: 30%;
      }
    }
  }
}

.order-history-order-item, .order-history-headers {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 5em;
  padding: 0 2em;

  @include remove-focus-border;

  &.headers {
    height: 2em;
    font-weight: bold;
  }

  .order-item-column {
    display: flex;
    flex-direction: column;

    &.wider-column {
      width: 16%;
    }

    &.narrower-column {
      width: 10%;
    }
  }



  .account {
    display: flex;
    flex-direction: column;

    .account-name {
      font-weight: bold;
    }

    .order-number {
      color: $gray-700;
      font-size: $font-size-xs;
    }
  }

  .order-status, .order-sales-value{
    font-weight: bold;

    &.pending {
      color: $gray-700;
    }

    &.cancelled {
      color: $red;
    }

    &.paid  {
      color: $primary
    }
  }
}

@mixin details-padding {
  padding: 1em;
}

.delivery-summary {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__title {
    font-weight: bold;
    text-align: center;
  }

  &__data {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 2em);
    @include details-padding;

    .delivery-summary-details {
      display: flex;
      justify-content: space-between;
      max-height: 75%;

      .section {
        display: flex;
        flex-direction: column;
        max-width: 30%;
        width: 30%;
        white-space: normal;
        height: 100%;
        font-size: smaller;

        &.address {
          p {
            margin: 0;
          }
        }

        .section-title {
          color: $gray-900;
          font-size: small;
          font-weight: 700;
          margin-bottom: 0.5em;
        }

        p {
          overflow: auto;
          font-weight: 100;
        }
        span {
          font-weight: 100;
        }
      }
    }

    .delivery-summary-action-buttons {
      display: flex;
      max-width: 100%;
      margin-top: 1em;

      .ordo-button {
        width: 20%;
        padding-left: 0;
        padding-right: 0;
      }

      .empty-space {
        width: 20%;
      }
    }
  }
}

.order-payment-summary {
  width: 100%;
  height: 100%;

  &__title {
    font-weight: bold;
    text-align: center;
  }

  &__data {
    display: flex;
    flex-direction: column;
    height: calc(100% - 2em);
    @include details-padding;
    font-size: smaller;
    font-weight: 900;

    &__details {
      white-space: nowrap;
      font-weight: 500;
      display: flex;
      flex-direction: row;
      padding: 0.2em;
      width: 100%;

      &__discount-names {
        width: inherit;

        &__item {
          display: flex;
          align-items: center;

          &__line {
            flex: 1;
            height: 1px;
            width: 50%;
            margin: 0 1em;
            background-color: $primary;
          }
        }
      }
    }

    &__button {
      margin-top: .75em;
      display: flex;
      justify-content: center;
    }
  }
}
