//
// Icon shapes
//


.icon-shape {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	vertical-align: middle;
	border-radius: $border-radius;

	i, svg {
		font-size: 1.125rem;
	}



	&.icon-xl {
		i, svg {
			font-size: 2.25rem;
		}
	}


	&.icon-lg {
		i, svg {
			font-size: 1.75rem;
		}
	}

	&.icon-sm {
		i, svg {
			font-size: 1rem;
		}
	}

	&.icon-xs {
		i, svg {
			font-size: .75rem;
		}
	}
}

@each $color, $value in $theme-colors {
    .icon-#{$color} {
        @include icon-variant(theme-color-level($color, $icon-bg-level), $value);
    }

	.icon-outline-#{$color} {
		@include icon-outline-variant($icon-border-width, $value);
	}
}
