@import '../../purpose/variables';
@import '../../custom/variables';
@import '../common';

.subscription-plans-card {
  padding: 1em 1em 2em;
  width: 13em;
  height: 28em !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: $black;

  &__plan-title {
    font-weight: 600;
    color: $primary;
    margin-bottom: 1em;
  }
  &__pricing {
    height: 5em;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__plan-price {
      height: 2em;
      font-size: 1.75em;

      .dollar-sign {
        font-size: .6em;
        vertical-align: super;
      }
    }

    &__plan-description {
      line-height: 1em;
      text-align: center;
      margin-bottom: 2em;
    }
  }

  &__plan-amount {
    text-align: center;
  }

  &__plan-features {
    height: 5em;
    margin-bottom: 1em;
    margin-top: .5em;
    line-height: 1.2em;
    text-align: center;

    &-platform {
      margin-bottom: .5em;
    }
  }

  &__plan-disclaimer {
    &:before{
      content: '*';
    }
    position: absolute;
    margin-top: -0.5em;
    font-style: italic;
    font-size: 0.5em;
    bottom:6px;
  }

  button {
    width: 9em;
    padding: 0.2em 1em;
  }
}

.selected {
  .subscription-plans-card__plan-title {
      font-weight: 700;
      font-size: 1.25em;
      margin-bottom: 1em;
  }

  .subscription-plans-card__plan-price {

    .dollar-sign {
    }
  }

  .subscription-plans-card__plan-description {
  }

  .subscription-plans-card__plan-features {
  }


}

.disclaimer:after{
  content: '*';
}
