$margin: 3.5em;

@import '../../../purpose/variables';
@import '../../../custom/variables';
@import '../../common';


.mobile-contacts-page-container {
  position: absolute;
  margin-left: -($margin);
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  padding: 0 0.5em;

  .mobile-contacts-page-filters {
    margin-bottom: 1em;
  }

  .mobile-contacts-page-header {
    display: flex;
    width: 100%;
    height: 6%;
    justify-content: space-between;

    button {
      height: 2em;
    }

    &.landscape {
      margin-bottom: 1em;

      button {
        height: 2em;
      }

      .mobile-add-contact-button {
        width: 22%;

      }
    }

    .mobile-add-contact-button {
      padding-right: 1em;
      width: 40%;
      height: 1.75em;

      .ordo-button {
        .button-content {
          line-height: 1;
        }
      }
    }
  }
}

.mobile-contacts-page-list-item {
  cursor: pointer;
  background-color: #fff;
  border-radius: 15px;
  margin-bottom: 1em;

  .mobile-contacts-page-item-info {
    display: flex;
    width: 100%;
    justify-content: space-around;
    color: black;
    padding: 0.2em .5em;

    .mobile-contact-email-and-phone {
      display: flex;
      flex-direction: column;
      width: 59%;
      justify-content: center;

      .item-info-field {
        line-height: 1.3;
        display: flex;
        flex-direction: row;
        justify-content: start;
        align-items: center;
        max-width: 100%;
        width: 100%;

        .description {
          max-width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .title {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100%;
          font-weight: bold;
        }

        .account-name {
          font-weight: bold;
          font-size: smaller;
          color: $dark-purple;
        }
      }
    }

    .item-info-field {
      line-height: 1.3;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 50%;

      &.account-info {
        width: 38%;
        align-items: start;
      }

      .title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        font-weight: bold;
      }

      .order-number {
        color: black;
        font-size: x-small;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .order-status {
        font-weight: bold;
        color: $dark-purple;
      }

      .account-name {
        font-weight: bold;
        font-size: smaller;
        color: $dark-purple;
        max-width: 105%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}


.contacts-list-footer {
  margin-bottom: 1em;
}

.contact-form-account-selection {
  button {
    height: 3em !important;
  }
}