@import '../common';
@import '../../purpose/variables';
@import '../../custom/variables';

.relative-pos{
  position: relative;
}

.order-line-item-note {
  width: 3em;
  display: flex;
  align-items: center;

  .options {
    font-size: 1.5em;
    width: 100%;
    .edit {
      color: $primary;
      cursor: pointer;
      margin-right: 0.5em;
    }
    .delete {
      cursor: pointer;
      color: $pastel-red;
    }
  }

  .add-note-button {
    padding: 0;
    line-height: 1;
    color: $primary;
    cursor: pointer;
    white-space: normal;
  }
}

.note-edit {
  display: flex;
  flex-direction: column;
  textarea {
    color: $dark
  }
  .foot-note {
    display: flex;
    flex-direction: row-reverse;
    color: $gray-700;
    font-size: .8em;
    padding-right: 0.5em;
  }
  .buttons {
    margin-top: 1.5em;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}