@import '../../custom/styles';
@import '../common';

.note-row {
  width: 100%;
  padding-left: 3em;
  padding-right: 3em;
  margin-bottom: 1em;
  display: flex;
  flex-direction: column;

  &__title-and-editing {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: $black;

    &__title {
      font-weight: bold;
      margin-bottom: 0.25em;
    }

    &__info {
      color: $primary;
      font-size: 0.9em;
      line-height: 1.25em;

      &__last-edited {

        &__title {
          font-weight: 800;
          margin-right: 0.5em;
        }
      }
    }

    &__editing {
      display: flex;
      justify-content: space-between;
      color: $primary;

      &__icon {
        cursor: pointer;
      }
    }
  }

  &__description {
    width: 100%;
    height: fit-content;
    max-height: 15em;
    white-space: pre-wrap;
    color: $black;
    font-weight: 400;
    font-family: $font-family-base;
  }

  @media (max-width: $mobile-breakpoint) {
    width: $mobile-row-width;
    padding-left: 0;
    padding-right: 0;
  }
}
