@import '../common';

$order-color: #bc9f63;
$activity-color: $ocean-green;
$incomplete-activity-color: #B0BBCA;
$note-color: $dark-purple;

.note-information {
  display: flex;
  align-items: center;
  color: $dark-purple;
  font-size: 0.8em;

  .contacts-icon {
    margin: 0 .5em;
  }
}

.account-activity-card {
  width: 100%;
  padding: .5em 1em;
  display: flex;
  align-items: flex-start;

  .information {
    width: 95%;
    display: flex;
    flex-direction: column;

    .activity-title {
      color: $primary;
      font-weight: bold;
      display: flex;
      align-items: center;

      span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 40ch;
      }
    }

    .description {
      color: $gray-800;
      font-size: 0.85em;

      span, pre {
        font-size: 1em;
      }
    }

    .collapsed {
      line-height: 1.5;
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;
      justify-content: space-between;
    }

    .extra-text {
      color: $primary;
      font-weight: bold;
      margin-right: 0.5em;
    }

    .actions {
      color: $primary;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .icon {
        margin-right: .5em;
        cursor: pointer;
        width: 1em;
        height: 1em;
      }
    }
  }

  .icon-container {
    width: 5%;
    max-width: 5%;
    font-size: 0.8em;
    margin-top: 1em;
    margin-right: .5em;

    &.order {
      margin-left: .25em;
      margin-right: .25em;
      color: #bc9f63
    }

    &.activity, &.external-activity {
      margin-right: 1em;
      svg {
        height: 1em;
        width: 1em;
      }

      color: $activity-color;

      &.incomplete {
        color: $incomplete-activity-color;
      }
    }

    &.note {
      color: $note-color;
    }
  }
}
