@import '../../../purpose/variables';
@import '../../../custom/variables';

.activity-template-creation-container {
  width: 100%;
  display: flex;
  flex-direction: column;

  .header {
    width: 100%;
    display: flex;

    .title {
      width: 75%;
      font-size: xx-large;
    }

    .buttons {
      width: 25%;
      height: fit-content;
      display: flex;
      justify-content: flex-end;
    }
  }

  .icon-selection {
    margin-left: .5em;
    display: flex;
    flex-direction: column;

    .title {
      font-size: large;
      color: $dark-purple;
      font-weight: bold;
    }

    .icons {
      display: flex;

      .icon {
        margin-right: .5em;
        border: 1px solid $gray-700;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.5em;
        cursor: pointer;

        &.selected {
          color: $primary;
          border: 1px solid $primary;

          svg {
            color: $primary
          }
        }

        svg {
          color: $gray-700;
          height: .5em;
          width: .5em;
        }
      }
    }
  }

  .questions {
    margin: 1em 0;
  }

  .add-activity-button {
    button {
      border: 1px solid $gray-600 !important;
      font-size: larger;
      border-radius: 10px !important;

      .button-content {
        font-size: large;
      }
    }
  }

  .activity-template-question-container {
    border: 1px solid $gray-600;
    padding: 1em;
    display: flex;
    flex-direction: row;
    margin-bottom: .5em;
    border-radius: 1em;

    .sorting-arrows {
      width: 5%;
      height: 6em;
      margin-right: 1em;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: $primary;
      justify-content: space-around;
    }

    .question-container {
      width: 95%;

      .question-header {
        display: flex;
        justify-content: space-between;
        font-size: large;
        width: 100%;

        .question-type-selector-container {
          width: 20%;
        }

        .ordo-checkbox-container {
          margin-top: .5em;
          font-size: medium;

          input {
            margin-right: 1em;
          }
        }
      }

      .question-bottom {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .activity-template-name, .question-name {
    border: none;
    color: $primary;
    cursor: pointer;
  }

  .question-name {
    padding-left: 0;
    width: 80%;
  }

  .question-buttons {
    width: 20%;
    margin-top: 1em;
    display: flex;
    justify-content: space-around;

    .delete-icon {
      color: $primary;
      cursor: pointer;
      margin-right: 1em;
      margin-bottom: .6rem;
      height: 1.5em;
      width: 1.5em;

      svg {

      }
    }
  }

  .response-preview {
    height: 1.5em;
    border-bottom: 1px solid $dark-purple;
    font-size: larger;
    color: $dark-purple;
    width: 50%;

    &.hidden {
      border-bottom: 1px solid transparent;
      height: auto;
    }

    .date-preview {
      .date-icon {
        margin-right: .5em;
        margin-bottom: .1em;
      }
    }

    .upload-file-preview {
      margin-top: .5em;

      .upload-file-preview-text {
        background-color: $dark-purple;
        color: white;
        font-weight: bold;
        font-size: small;
        padding: 0.5em 1.5em;
        border-radius: 3em;
      }
    }

    .product-dropdown-preview {
      margin-top: .5em;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 50%;
      border: 1px solid $dark-purple;
      border-radius: 3em;
      padding: 0 1.5em;
      font-size: small;

      .product-dropdown-text {
        color: $dark-purple;
        font-weight: bold;
        padding: 0.5em 0;
      }

      .icon {
        width: 1em;
        height: 2em;
      }
    }

    .options-containers {
    }

    .option-container {
      margin-bottom: .5em;
      display: flex;
      align-items: center;

      .option-icon {
        color: $dark-purple;
        margin-right: .5em;
      }

      .delete-option-icon {
        color: $danger-darkest;
        margin-top: .5em;
        margin-left: .5em;
      }

      .option-name-container {
        width: 100%;
        border-bottom: 1px solid $gray-700;

        .option-name {
          color: $gray-700;
          border: none;
        }
      }
    }

    .add-option-button {
      color: $primary;
      cursor: pointer;

      &.disabled {
        cursor: auto;
        color: $gray-700;
      };

      svg {
        margin-right: .5em;
      }
    }
  }
}