@import 'common';

.pagination-buttons-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .pagination-buttons {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;

    &.collapsed {
      width: 100%;
    }

    .current-page-input {
      width: 6em;
      justify-content: center;
      display: flex;
      align-items: center;
      font-size: 1.1em;

      input {
        height: 10%;
        width: 40%;
        margin-right: .5em;
        text-align: center;
        border: 1.5px $primary solid;
        border-radius: 4px;
      }
    }

    .move-to-page-container {
      color: $primary;
      margin-left: .8em;
      display: flex;
      align-items: center;
      font-size: 1.1em;
      cursor: pointer;

      &.disabled {
        cursor: auto;
        color: $gray-700
      }

      &.back {
        margin: 0 .8em 0 0;
      }
    }
  }
}
