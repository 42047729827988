//
// Kanban container
//


.kanban-board {
    flex: 1;
    white-space: nowrap;
    overflow-x: scroll;
    display: flex;
    user-select: none;
    margin-right: -$grid-gutter-width / 3;
    margin-left: -$grid-gutter-width / 3;
}

.kanban-col {
    max-width: 19.5rem;
    user-select: none;
    flex: 1 0 auto;
    width: calc(100vw - 24px);
    max-height: 100%;
    padding-right: $grid-gutter-width / 3;
    padding-left: $grid-gutter-width / 3;
    outline: none;

    .card-list {
        user-select: none;
        max-height: 100%;
        height: 100%;
        margin-bottom: 0;
        overflow: hidden;
        display: flex;
        flex-direction: column;
    }

    .card-list-body {
        // added 100% height in order to drop draggable items in any place
        height: 100%;
    }

    .card-list-flush {
        border-right: 1px solid $card-border-color;
    }

    &:first-child {
        .card-list-flush {
            margin-left: 1rem;
        }
    }

    &:last-child {
        .card-list-flush {
            margin-right: 1rem;
            border-right: 0;
        }
    }
}
