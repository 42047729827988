@import '../../purpose/variables';
@import '../../custom/variables';
@import '../common';

.activity-items {
  width: 100%;
  min-width: 100%;
}

.activity-page-filters {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.account-and-type-filter {
  display: flex;
  width: 73%;

  .sorting-date-filter {
    display: flex;
    align-items: center;
    cursor: pointer;

    .sort-icon {
      height: 2em;
      width: 1.5em;
      color: $gray-900;
      margin-right: .5em;
      transform: rotate(90deg);
    }
  }
};

.activities-state-filters {
  display: flex;
  flex-direction: row;
  white-space: nowrap;
}

.activities-state-filter {
  font-weight: bold;
  cursor: pointer;
  padding: .2em .5em;
  margin-left: 1em;

  &.checked {
    color: $primary;
    border: 1px solid $primary;
    border-radius: 4px;
  }
}

.activities-list-headers {
  width: 100%;
  height: 2em;
  display: flex;
  font-weight: bold;
  color: black;

  .status {width: 5%}
  .account {width: 15%}
  .activity-name {width: 15%}
  .contact {width: 13%}
  .date {width: 10%}
  .description {width: 20%}
  .assigned {width: 15%}
  .edit-activity {width: 3%}
  .delete-activity {width: 3%}
}

.activity-item {
  height: 4em;
  display: flex;
  align-items: center;
  border-top: .5px solid $gray-600;
  width: 100%;

  .activity-item-status {
    width: 5%;
    display: flex;
    justify-content: center;
  }

  .activity-item-account-name, .activity-item-template-name, .activity-item-assigned {
    width: 15%;
    @include text-ellipsis(15%);
    white-space: nowrap;
    padding-right: .5em;
  }

  .activity-item-account-name {
    color: $dark-purple;
    font-weight: 600;
    span:hover {
      cursor: pointer;
      text-decoration: underline $primary;
    }
  }

  .activity-item-contacts{
    width: 13%;
    @include text-ellipsis(13%);
    white-space: nowrap;
    padding-right: .5em;
  }

  .activity-item-date {
    width: 10%;
  }

  .activity-item-description {
    width: 20%;
    @include text-ellipsis(20%);
    white-space: nowrap;
    padding-right: .5em;
  }

  .activity-item-edit-icon, .activity-item-delete-icon {
    color: $primary;
    width: 3%;
    cursor: pointer;
  }

  .activity-contacts-container {
    display: flex;
    .activity-contact {
      margin-right: .2em;
      width: fit-content;
      padding: 0 .5em;
      border-radius: 5px;
      color: white;
      background-color: $dark-purple;

      &.ellipsis {
        @include text-ellipsis(100%);
        white-space: nowrap;
      }
    }
  }

  .activity-contacts-container:hover {
    cursor: pointer;
  }
}


.wide-modal {

  .modal-dialog {
    max-width: 800px;
  }
}
