@import '../../purpose/variables';
@import '../../custom/variables';
@import '../common';

$content-padding: 1em;
$row-padding: 1.5em;
.pricing-tiers-card {
  padding: 1.5em;
  border-radius: $border-radius;
  width: 100%;
  min-height: 70vh;
}
.custom-tier-list {
  width: 100%;

  .pricing-tiers-header {
    display: flex;
    width: 100%;
    flex-direction: row-reverse;
  }

  .pricing-tiers-content {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 1.5em;

    &__header {
      width: 100%;
      display: flex;
      padding: 0 ($content-padding + $row-padding);
      font-weight: bold;
      font-size: 1.15em;
    }

    &__body {
      margin-top: .5em;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0 $content-padding;

      & > .custom-pricing-tier-row {
        margin-bottom: 1em;
      }
    }

  }
}

.pricing-tier {width: 75%;}
.accounts {width: 20%;}
.options {width: 5%;}

.custom-pricing-tier-row {
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid $primary;
  border-radius: 10px;
  padding: 0 $row-padding;
  height: 3em;
  font-size: 1.1em;

  .options {
    svg {
      font-size: 1.5em;
    }
  }
}

.custom-tier-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1em;

  .ordo-input {
    &__title {
      font-size: 1.1em;
      padding-left: 1em;
      line-height: 2.5;
      color: $gray-700;
      &:after{
        content: '*';
        font-weight: bold;
        color: $pastel-red;
      }
    }
    input {
      box-shadow: none;
    }
  }

  &__custom-price-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    font-size: 1.1em;
    padding-left: 1em;
    margin-bottom: 2em;

    .header {
      display: flex;
      flex-direction: row;
      margin-bottom: 1em;
      & > * {
        margin-right: 2em;
      }
      .title {
        color: $gray-700;
        font-weight: bold;
        margin-right: 2em;
      }
    }

  }

  &__accounts-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    font-size: 1.1em;

    .title {
      padding-left: 1em;
      color: $gray-700;
      font-weight: bold;
      line-height: 2.5;
    }

  }

  &__buttons-container {
    margin-top: 2em;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.custom-price-editor {
  margin-top: 1em;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .ordo-searchable-dropdown-container {
    box-shadow: none;
    border: none;
    height: 2em;
    margin-bottom: 0;
    margin-right: 2em;
  }

  &.group {
    .ordo-searchable-dropdown-container {
      width: 16%;
    }
  }

  &.product-sku {
    .ordo-searchable-dropdown-container {
      width: calc(63% + 7em);
      .dropdown-label {
        padding-right: 0;
        &:after {
          content: ' *';
          font-weight: bold;
          color: $pastel-red;
        }
      }
    }

  }


  .ordo-number-input {
    margin-top: -4px;
    margin-right: 2em;
    width: 16%;
    input {
      width: 100%;
      border-width: 1px;
    }
    .input-label {

      white-space: nowrap;
    }
  }

  .delete-row {
    margin-right: 2em;
    cursor: pointer;
    font-size: 1.5em;
    color: $pastel-red;
  }
}

.sku-price-editor {
  margin-top: 1em;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  .ordo-searchable-dropdown-container {
    width: 16%;
    box-shadow: none;
    border: none;
    height: 2em;
    margin-bottom: 0;
  }

  .ordo-number-input {
    margin-top: -5px;
    width: 16%;
    input {
      width: 100%;
    }
    .input-label {

      white-space: nowrap;
    }
  }

  .delete-row {
    margin-right: 2em;
    cursor: pointer;
    font-size: 1.5em;
    color: $pastel-red;
  }
}


.product-dropdown-view {
  display: flex;
  color: $black;
  font-size: .9em;
  font-weight: 600;
  line-height: 1;
  width: 99%;
  align-items: center;
  .info {
    display: flex;
    flex-direction: column;
    width: 25%;

    .title {
      font-weight: 600;
      text-overflow: ellipsis;
    }
    .subtitle {
      font-size: .70em;
      font-weight: 500;
    }
  }

  .field {
    white-space: nowrap;
    width: 20%;
    margin-right: 1em;
  }

  &.menu {
    margin-bottom: .5em;
    padding: .25em 6% .5em 1.4em;
    cursor: pointer;
    border-bottom: 1px solid $gray-300;
    &:hover {
      background-color: whitesmoke;
    }
  }
}