@import 'common';
@import '../custom/styles';

.spinner-container {
  position: fixed;
  z-index: 9998;
  left: 0;
  top: 0;

  width: 100% !important;
  height: 100%;

  @include blur-page;

  .ordo-spinner {
    position: absolute;
    z-index: 9999;
    left: 50%;
    top: 50%;

    color: $ocean-green;
  }
}

.spinner-content-container {
  @include not-allow-select-text;
  @include not-allow-clicks;
}
