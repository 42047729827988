//
// Navbar dropdown
//


.navbar {
    .dropdown-menu {
        min-width: $navbar-dropdown-menu-width;
        padding: $navbar-dropdown-padding-y $navbar-dropdown-padding-x;
        background-color: $navbar-dropdown-bg;
        border: $navbar-dropdown-border-width solid $navbar-dropdown-border-color;
        @include border-radius($navbar-dropdown-border-radius);
        @include box-shadow($navbar-dropdown-box-shadow);


        // List group

        .list-group > li {
            &:first-child {
                .list-group-item {
                    @include border-top-radius($navbar-dropdown-border-radius);
                }
            }

            &:last-child {
                .list-group-item {
                    @include border-bottom-radius($navbar-dropdown-border-radius);
                }
            }
        }
    }

    // Dropdown items

    .dropdown-header {
        color: $navbar-dropdown-heading-color;
    }

    .dropdown-item {
        padding: $navbar-dropdown-item-padding-y $navbar-dropdown-item-padding-x;
        color: $navbar-dropdown-link-color;
        font-size: $navbar-dropdown-link-font-size;

        &:first-child {
            @include border-top-radius($navbar-dropdown-border-radius);
        }

        &:last-child {
            padding-bottom: 0;
            @include border-bottom-radius($navbar-dropdown-border-radius);
        }

        @include hover-focus {
            color: $navbar-dropdown-link-hover-color;
            text-decoration: none;
            background: transparent;
        }

        &.active,
        &:active {
            color: $navbar-dropdown-link-active-color;
            text-decoration: none;
            @include gradient-bg($navbar-dropdown-link-active-bg);
        }
    }

    // Additional dropdown links menu

    .dropdown-menu-links {
        background-color: darken($navbar-dropdown-bg, 2%);
    }
}

// Generate series of `.navbar-expand-*` responsive classes for configuring
// where your navbar collapses.

.navbar-expand {
    @each $breakpoint in map-keys($grid-breakpoints) {
        $next: breakpoint-next($breakpoint, $grid-breakpoints);
        $infix: breakpoint-infix($next, $grid-breakpoints);

        &#{$infix} {
            @include media-breakpoint-down($breakpoint) {

                .navbar-collapse {
                    .dropdown-menu {
                        background: transparent;
                        padding-top: 0;
                        padding-bottom: 0;
                        padding-left: 1rem;
                        padding-right: 0;
                    }

                    .dropdown-menu-links {
                        background: transparent;

                        &:before {
                            background: transparent;
                        }
                    }

                    .dropdown-item {
                        position: relative;
                        font-size: $navbar-nav-link-font-size;
                        font-weight: $navbar-nav-link-font-weight;


                    }

                    .dropdown-submenu {
                        &.show {
                            .dropdown-toggle:after {
                                transform: rotate(90deg);
                                @include transition($transition-base);
                            }
                        }
                    }


                    .nav-item.dropdown {
                        .dropdown-toggle {
                            &.nav-link {
                                padding-right: 1rem;

                                &:after {
                                    transform: rotate(-90deg);
                                    @include transition($transition-base);
                                }
                            }

                            &:after {
                                font-size: .75rem;
                                float: right;
                            }
                        }

                        &.show {
                            .dropdown-toggle {
                                &.nav-link {
                                    &:after {
                                        transform: rotate(0);
                                    }
                                }
                            }
                        }
                    }
                }

                &.navbar-dark {
                    .navbar-collapse {
                        .dropdown-item {
                            color: $navbar-dark-color;
                        }
                    }
                }

                &.navbar-light {
                    .navbar-collapse {
                        .dropdown-item {
                            color: $navbar-light-color;
                        }
                    }
                }
            }

            @include media-breakpoint-up($next) {
                .dropdown:not(.dropdown-submenu):not(.dropdown-fluid) {
                    > .dropdown-menu:not(.dropdown-menu-right) {
                        margin-left: -1.25rem;
                    }
                    > .dropdown-menu-right {
                        margin-right: -1.25rem;
                    }
                }

                // Dropdown menu
                .dropdown-menu {
                    .list-group > li {
                        .list-group-item {
                            border: 0;
                            transform: scale(1);
                            @include transition($transition-base);
                        }

                        .media-body * {
                            @include transition($transition-base);
                        }

                        &:hover {
                            .list-group-item {
                                z-index: 11;
                                transform: scale(1.05);
                                background-color: $navbar-dropdown-link-hover-bg;
                                @include box-shadow($box-shadow);
                                @include border-radius($navbar-dropdown-border-radius);
                            }
                        }
                    }
                }

                // Dropdown submenu
                .dropdown-submenu {
                    .dropdown-menu {
                        min-width: 16rem;
                        margin-left: 1rem;

                        &:before {
                            content: '';
                            border-left: 1.5rem solid transparent;
                            position: absolute;
                            left: -1rem;
                            top: 0;
                            height: 100%;
                        }

                    }

                    .dropdown-item {
                        &:after {
                            right: $navbar-dropdown-item-padding-x;
                        }
                    }
                }

                // Dropdown fluid
                .dropdown-fluid {
                    //position: static;

                    .dropdown-menu {
                        overflow: hidden;
                        padding: 0;
                        min-width: 1000px;

                        // &.w-50 {
                        //     min-width: calc(50% - 30px);
                        // }
                    }

                    .dropdown-col-image {
                        position: absolute;
                        left: 0;
                        top: 0;
                        height: 100%;
                        overflow: hidden;
                    }
                }
            }
        }
    }
}
