@import '../common';

.contact-form {
  display: flex;
  flex-direction: column;

  &__mail-and-phone {
    display: flex;

    .mail {
      margin-left: 1em;
      width: 65%;
    }
    .phone {
      width: 35%;
    }

    @media (max-width: $mobile-breakpoint) {
      flex-direction: column;
      .mail {
        margin-left: 0;
        width: 100%;
      }
      .phone {
        width: 100%;
        margin-bottom: 1em;
      }
    }
  }
}
