@import '../../purpose/variables';

.note-form {
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    .ordo-input {
      width: 45%;
      min-width: 40%;
    }

    .ordo-multi-select {
      width: 100%;
      min-width: 55%;
      height: fit-content;
    }
  }

  &__description {
    margin-bottom: 1em;

    .ordo-text-area {
      min-height: 12em;
    }

    textarea:focus {
      border-color: $input-focus-border-color;
    }
  }
}
