@import 'common';

$selected-color: rgba($primary, .075);
$hover-color: rgba($primary, .95);

.action-item {
  border: 1px solid #E0E6ED;
  vertical-align: baseline;
}
.tall {
  height: calc(1.5rem + 1.5rem - 3px);
}

.short {
  height: 2.5em !important;
}

.action-item::after{
  display: none !important;
}

.action-item::before{
  display: none !important;
}

.show > .btn-primary.dropdown-toggle {
  background-color: $hover-color;
  color: $white;
}

.action-item:hover{
  background-color: $hover-color;
  color: $white;
}

.toggle-tag {
  margin-right: 0.75em;
  text-align: left;
  width: 85%;
}

.selected-text {
  color: $black;
}

.dropdown-menu {
  border-color: lighten($primary, 25%);
}

.dropdown-item:hover {
  background-color: $selected-color;
}

