@import '../../ordo/common';

.state-map {
  .leaflet-layer {
    position: absolute;
    left: 0;
    top: 0;
  }

  .leaflet-top{
    z-index: $Z_INDEX_MAX - 20;
  }

  .leaflet-container {
    background-color: white;
  }

  // This is the class of each county shape
  .leaflet-interactive {
    // Stroke is the border of the shape
    stroke-opacity: 1;
    stroke-width: 2;
    stroke: whitesmoke;
    // fill is the color of the shape
    fill-opacity: 0.8;
  }

  .leaflet-interactive:hover {
    fill: $success;
  }

  #map {
    width: 100%;
    height: 100%;
  }

  .county-label {
    height: min-content;
    padding: 0;
    line-height: .75;
    width: min-content;
    background: transparent;
    border: none;
    box-shadow: none;
    color: $dark;
    font-size: .7em;
    font-weight: 600;
    text-shadow: 2px 0 0 #fff, -2px 0 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff, 1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff;
  }
  .county-label::before {
    border: none;
  }

}

