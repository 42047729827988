@import '../common';

.payment-history-modal {
  display: flex;
  flex-direction: column;
  padding: 0 3em;
  align-items: center;
  .payment-history-row:first-child {
    padding-top: 0;
  }
  .payment-history-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 1em;
    &__date {
      color: $black;
      font-weight: bold;
    }
    &__amount {

    }
  }

  .separator {
    width: 80%;
    border-bottom: 2px solid transparentize($table-separator-color, 0.5);
  }
}