@import '../../purpose/variables';
@import '../../custom/variables';
@import '../common';

.registration-header {
  margin-bottom: 1em;

  &__title {
    font-size: 1.25rem;
  }
}

.registration-footer {
  display: flex;
  flex-direction: column;

  .back-and-create-buttons {
    margin-top: 1em;
    display: flex;
  }
}


.ordo-searchable-dropdown-container {
  margin-bottom: 16px;
}

.state-input {
  display: flex;
  flex-direction: column;
}

.agreement-terms {
  color: $gray-800;
  margin-left: 0.5em;

  a {
    color: $primary;
  }
}

.subscription-plans {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 56em;
  margin-top: 3em;
}

.is-modal {
  margin-top: 1.5em;
  width: 100%;
}
.wide-modal-dialog {
  max-width: 800px;
}
.edit-subscription-modal {
  .modal-content {
    width: 60em;
    .modal-body {
      width: 55em;
    }
  }
}
