@import '../../purpose/variables';
@import '../common';

.activity-form {
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;

  .multi-selector-with-tags {
    .ordo-searchable-dropdown-container {
      margin-bottom: .5em;
    }
    margin-bottom: .5em;

    &__selected {

      & > .clickable-tag {
        height: 2em;
        margin-right: .4em;
        margin-bottom: .4em;
        .remove-icon {
          margin-left: .5em;
        }
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    .select-type {
      width: 100%;
      margin-bottom: 1em;

      .ordo-dropdown {
        &__button-toggle {
          .toggle-tag {
            width: 90%;
          }
        }
      }
    }

    .ordo-input {
      width: 45%;
      min-width: 40%;
    }

    .ordo-multi-select {
      width: 100%;
      min-width: 55%;
      height: fit-content;
    }
  }

  &__description {
    textarea:focus {
      border-color: $input-focus-border-color;
    }
    textarea {
      padding-top: .5em;
      height: 10.1em;
    }

    margin-bottom: 1em;
  }

  &__completed {
    display: flex;
    justify-content: center;
    margin-top: 1em;

    .text {
      margin-left: .5em;
    }
  }

  &__date-and-type {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1em;

    @media (max-width: $mobile-breakpoint) {
      .ordo-datepicker-container .rdtPicker {
        right: auto;
      }
    }
  }

  .date-and-time {
    display: flex;
    margin: .5em 0;
    align-items: center;
    .time-picker-container {
      margin-left: 1em;
    }
  }
}
