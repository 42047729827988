@import '../../purpose/variables';
@import '../../custom/variables';
@import '../common';

.inventory-list {
  margin-top: 2.5em;

  td {
    border-top: none;
    padding: 0.25em 0.5em!important;
  }
}

.table-headers {
  color: $gray-900;

  &.with-border-bottom {

    th {
      font-weight: 700;
      font-size: $font-size-base;
      border-bottom: 1px solid $table-header-separator-color;
      border-top: none;
      padding: 0.5em !important;
    }
  }
}

.inventory-list-card {
  display: flex;
  flex-direction: row;
  margin: 10px 0;
  border-radius: $border-radius;

  .inventory-list-button-header {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  .inventory-list-card-content {
    display: flex;
    flex-direction: column;
    padding: 1.5em;

    .table-responsive {
      height: 100%;
    }
  }
}

.no-product-width {
  width: 100%;
}

.no-product-content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
  color: $gray-900;
}

.sort-icon {
  height: 2em;
  width: 1.5em;
  color: $gray-900;
  margin-left: .5em;
  cursor: pointer;
  transform: rotate(90deg);
}

.name-column {
  display: flex;
  align-items: center;
}