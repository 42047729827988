@import '../purpose/variables';
@import '../custom/variables';
@import 'common';

.multicontact-tooltip-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  visibility: hidden;
  left: 38em;
  top: -1em;
  height: auto;
  z-index: 10;

  &__contact {
    padding: 1em;
    width: 100%;
    border-bottom: 1px solid $table-header-separator-color;

    &__header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .contact-name-and-title {
        display: flex;
        flex-direction: column;
        color: $dark-purple;

        .name {
          font-size: small;
          font-weight: bold;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 20ch;
        }

        .title {
          margin-left: 0.25em;
          font-size: smaller;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 20ch;
        }
      }

      .contact-edit-and-delete {
        display: flex;
        flex-direction: row;
        color: $primary;

        .edit-icon {
          margin-left: .5em;
          cursor: pointer;
        }
      }
    }

    &__fields {
      padding-left: 2em;
      color: $dark;

      .contact-field {
        display: flex;
        align-items: center;

        &__icon {
          margin-right: 1em;
        }
      }
    }
  }

  &__contact:last-child {
    border-bottom: none;
  }

  &.contact-row-tooltip-visible {
    visibility: visible;
  }

  &:hover {
    visibility: visible;
  }
}

