@import 'common';

.ordo-popup {
  background-color: white;
  padding: 1em;
  border-radius: $border-radius;
  z-index: 1;
  position: absolute;
  @include ordo-shadow();
  .header {
    display: flex;
    justify-content: space-between;
    padding-bottom: .5em;
    .title {
      font-weight: bold;
      color: $dark;
    }
    svg {
      cursor: pointer;
      font-size: 1.3em;
      color: $pastel-red
    }
  }
}