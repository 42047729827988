@import '../ordo/common';

@media (max-width: $mobile-breakpoint) {
  .mobile-modal-wrapper {
    .modal-dialog {
      height: 100%;

      .modal-content {
        height: 100%;
      }
    }
  }
}
