@import '../../custom/styles';
@import '../common';

.order-details-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.account-header-in-order-details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: $black;
  padding: 1em;
  width: 100%;

  &__account-personal-info {
    width: 88%;

    &__name {
      font-weight: 800;
      font-size: 1.5em;
      cursor: pointer;
      text-overflow: ellipsis;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
    }

    &__info {
      display: flex;
      font-size: $font-size-base;
      line-height: 1;

      &__license {
        font-weight: 600;
        margin-right: 1em;
      }

      &__address {
        overflow: hidden;
        text-overflow: ellipsis;
        padding-bottom: 0.3em;
      }
    }
  }

  .order-details-info {
    border-bottom: lighten($table-separator-color, 20) solid 2px;
    padding: 1em;
  }
}

.account-header {
  &__account-personal-info {
    width: 88%;
    &__info {
      display: flex;
      font-size: $font-size-base;
      line-height: 1;

      &__stale-period {
        display: flex;
        align-items: center;
        padding: 0.25em;
        height: 2.5em;

        &__conversion {
          display: flex;
          align-items: center;
          @media (max-width: $mobile-breakpoint) {
            width: 52%;
          }
          &__text {
            color: $primary;
            font-weight: 600;
            margin-right: .5em;
          }

          &__input {
            margin-top: .5em;
            margin-right: 1em;
            label {
              input {
                width: 5em;
                padding: 0 .9em;
              }
            }
          }
        }
      }
    }
  }

  &__priority-icon {
    height: 2em;
    width: 2em;
    margin-right: 3em;
  }

  &__priority-icon:hover {
    cursor: pointer;
  }
}

.order-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .btn {
    padding: 0.2em 1em;
  }
}

.order-details-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.order-details-info {
  display: flex;
  flex-direction: column;
  margin-left: 1em;
  margin-right: 4em;
  line-height: 1;
}


.order-number-and-account {
  width: 100%;
  color: black;
  font-size: 1em;
  line-height: 1.3;
  display: flex;

  &__order {
    font-weight: 800;
    margin-right: .25em;
  }
}

.order-account-info {
  color: black;

  &__status {
    font-size: .9em;
  }
}

.case-sale-price-subtext {
  font-size: smaller;
}

.order-details-items {
  margin-bottom: 0.5em;

  .product-size {
    justify-content: center;
    font-size: 1em;
    margin-top: 0.5em;
  }

  .ordo-product-preview__image {
    width: 3em;
    height: 3em;
    border-radius: 5px;
    margin-right: 0.5em;
  }

  .ordo-table__cell {
    padding: 0 0.6rem !important;
  }
}

.mobile-order-notes-and-address {
  display: flex;
  font-size: smaller;
  margin-top: .5em;

  .section {
    width: 30%;

    &.address {
      width: 70%;
    }
  }
}

.order-details-body__summary {
  display: flex;

  &__delivery {
    width: 50%;
    border-right: 3px solid $primary;

    .delivery-summary {
      &__data {
        .delivery-summary-details {
          flex-direction: column;

          .section {
            max-width: 100%;
            width: 100%;
            margin-bottom: 1em;
            font-size: smaller;
            line-height: 1.2;

            &.address {
              p {
                margin: 0;
              }
            }

            .section-title {
              font-size: 1em;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  &__payment {
    width: 50%
  }

  @media (max-width: $mobile-breakpoint) {
    flex-direction: column;
    &__delivery {
      width: 100%;

      .delivery-summary {
        &__data {
          .delivery-summary-details {
            display: flex;
            flex-direction: row;
          }

          .mobile-order-notes-and-address {
            display: flex;
            font-size: smaller;
            align-items: flex-start;
            justify-content: space-between;
            width: 100%;
            line-height: 1.2;

            .address {
              width: 66%;

              p {
                margin: 0;
              }
            }

            .section-title {
              color: $black;
              font-size: 1em;
              font-weight: 700;
            }

            .mobile-order-notes {
              display: flex;
              flex-direction: column;
              max-width: 33%;
              width: 33%;
              align-content: center;
              justify-content: center;
            }
          }
        }
      }
    }

    &__payment {
      width: 100%;

      .order-payment-summary__data__button {
        width: 50%;
        align-self: center;
      }
    }
  }

}

@media (max-width: $mobile-breakpoint) {
  .order-details-header {

    .order-details-info {
      .order-number-and-account {
        font-size: 1em;
      }
    }

    .order-buttons {
      flex-direction: column;
      .btn {
        margin-right: 0;
        width: 7em;
      }
      .btn:not(:last-child) {
        margin-bottom: .25em;
      }

      .ordo-little-button {
        padding: 0.5em;
        white-space: nowrap;
        justify-content: center;

        .little-button-text {
          font-size: .8em;
        }
      }
    }
  }
}
