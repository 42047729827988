.link-accounts-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .selected-accounts {
    width: 100%;

    .account-list-container {
      max-height: 16.8em;
      overflow-y: scroll;

      .account-list, .account-modal {
        .account-card-container {
          width: 97%;
          min-width: 97%;
          margin: 0.5em 0;
        }
      }
    }
  }
}
