@import '../custom/styles';
@import 'common';

.overlay {
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  position: fixed;
  background-color: #1F2D3D;
  opacity: 0.2;
  z-index: $Z_INDEX_MAX;
  animation-name: fadein;
  animation-duration: 0.4s;

  @keyframes fadein {
    from {opacity: 0;}
    to {opacity: 0.2;}
  }
}
