@import '../../purpose/variables';
@import '../../custom/variables';
@import '../../ordo/common';

$account-card-height: 6.5em;
$account-card-margin: 0.4em;
$amount-of-visible-cards: 4;
$account-list-full-width: 80%;
$account-list-collapsed-width: 22em;
$account-list-height: 49em;

.accounts-page-content {
  border-radius: $border-radius;
  background-color: $white;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 2em;
  height: 48em;

  .account-list {
    max-height: 80em;
    height: min-content;
  }

  .no-accounts-found {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .wrapper-for-map {
    display: flex;

    .area-summary {
      position: absolute;
      z-index: $Z_INDEX_MAX - 30;
      background-color: $white;
      right: 1em;
      top: 1em;
      padding: 1.5em 1em 1.5em 1em;
      border-radius: $border-radius;
      width: 15%;
      color: $black;

      &__title {
        padding-bottom: 1.5em;
        font-size: 0.8rem;
        font-weight: bold;
      }

      &__counter {
        display: flex;
        justify-content: space-between;
      }
    }

    .counties-filter-indicator {
      position: absolute;
      z-index: $Z_INDEX_MAX - 30;
      background-color: $white;
      right: 1em;
      top: 30%;
      padding: 1.5em 1em 1.5em 1em;
      border-radius: $border-radius;
      width: 15%;
      color: $black;

      &__title {
        padding-bottom: 0.5em;
        font-size: 0.8rem;
        font-weight: bold;
      }

      &__counties-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
    }

    .state-map {
      width: calc(100% - #{$account-list-collapsed-width});
      height: $account-list-height;
    }

  }
}

.accounts-page {
  height: 100%;

  .account-assign-rep {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .account-list-container {
    padding: 0 1em;
    overflow-y: hidden;
    overflow-x: hidden;
    height: $amount-of-visible-cards * ($account-card-height + $account-card-margin);

    &.collapsed {
      padding: 0;
      overflow-y: scroll;
      overflow-x: hidden;
      height: $amount-of-visible-cards * ($account-card-height + $account-card-margin);

      .account-list {
        padding: 0 0.5em;
      }

      .account-card-container {
        width: 100%;
        max-width: 100%;
      }
    }
  }

  .account-list-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1em;
  }

  .pagination-buttons-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .pagination-buttons {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;

      &.collapsed {
        width: 100%;
      }

      .current-page-input {
        width: 6em;
        justify-content: center;
        display: flex;
        align-items: center;
        font-size: 1.1em;

        input {
          height: 10%;
          width: 40%;
          margin-right: .5em;
          text-align: center;
          border: 1.5px $primary solid;
          border-radius: 4px;
        }
      }

      .move-to-page-container {
        color: $primary;
        margin-left: .8em;
        display: flex;
        align-items: center;
        font-size: 1.1em;
        cursor: pointer;

        &.disabled {
          cursor: auto;
          color: $gray-700
        }

        &.back {
          margin: 0 .8em 0 0;
        }
      }
    }
  }
}

.account-card-container {
  max-width: 23%;
  min-width: 12.5em;
  height: $account-card-height;
  margin: $account-card-margin !important;
  width: 23%;

  .account-card {
    display: flex;
    padding: .5em 1em;
    flex-direction: column;
    justify-content: center;

    .edit-link-button {
      position: absolute;
      left: 9.75em;
      bottom: .5em;
    }

    .account-assignments {
      .account-assignments-info {
        position: absolute;
        right: .75em;
        top: .3em;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: x-small;
        font-weight: bold;
        background-color: $dark-purple;
        height: 1.7em;
        width: 1.7em;
        color: white;
        border-radius: 100px;
      }

      .tooltip-info {
        position: absolute;
        right: 0.25em;
        top: 1.7em;
        visibility: hidden;
        display: flex;
        flex-direction: column;
        width: max-content;
        height: fit-content;
        border-radius: $border-radius;
        padding: 0.75em;
        background-color: $dark-purple;
        color: white;
        text-align: center;
        font-weight: 600;
        font-size: x-small;
        z-index: 1;
      }

      .account-unassign-cross {
        position: absolute;
        right: .6em;
        top: .2em;
        display: flex;
        align-items: center;
        justify-content: center;
        visibility: hidden;
      }
    }

    .account-assignments-tooltip:hover .tooltip-info {
      visibility: visible;
    }

    .account-assignments-delete:hover .account-unassign-cross {
      visibility: visible;
    }

    .account-assignments-delete:hover .account-assignments-info {
      visibility: hidden;
    }

    .account-name {
      color: $gray-800;
      font-size: smaller;
      line-height: 1.2;
      font-weight: bold;

      .new-account-label {
        color: $primary;
      }
    }

    .account-address-container {
      display: flex;
      flex-direction: column;
      span {
        line-height: 1;
      }
      .account-address {
        font-size: .6rem;

        &.city {
          line-height: 1.3;
        }

        &.license {
          color: $dark-purple;
          margin-bottom: 0.25em;
        }
      }
    }
  }
}

