@import '../purpose/variables';
@import '../custom/variables';

::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: $primary;
  border-radius: $border-radius;
}
