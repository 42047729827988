@import '../../purpose/variables';
@import '../../custom/variables';
@import '../common';
@import 'accounts-page';
$account-drawer-z-index: $Z_INDEX_MAX - 10;

.assignment-container {
  outline: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  th {
    padding-left: 100px;
  }
  td {
    padding-left: 100px;
    vertical-align: top;
  }
  .brand-logo-container {
    display: inline-block;
    width: auto;
    outline: none;
    border-radius: 5px;
    margin-right: 10px;
    box-shadow: 1px 1px 1px 1px #EFF2F7;
    border: 1px solid #EFF2F7;
    border-radius: 5px;

    img {
      height: 50px;
      width: 50px;
      border-radius: 5px;
    }
  }


  .card {
    outline: none;
    margin-top: 20px;
    background-color: white;
    border-radius: $border-radius;

    .card-body {
      height: min-content;
    }

    &:hover{
      cursor: pointer;
      background-color: #F8FAFD;
    }
  }
}

.account-container {

  .drawer-toggle-button {
    position: absolute;
    right: 8px;

    button {
      padding: .5em .75em;
    }

    svg {
      margin: 0;
    }
  }

  .drawer-toggle-button--collapsed {
    margin-right: 1.5em;
  }



  .account-box {
    border-radius: $border-radius;
    padding: 2em 2em;
    width: $account-list-collapsed-width;
    background-color: $white;
    height: $account-list-height;
  }

  .floating-panel {
    position: absolute;
    z-index: $account-drawer-z-index;
  }

  .account-list-expanded {
    animation-name: drawer-expand;
    width: $account-list-full-width;
    animation-duration: 0.3s
  }

  .account-list-collapsed {
    width: $account-list-collapsed-width;
    animation-name: drawer-collapse;
    animation-duration: .3s
  }

  @keyframes drawer-expand {
    from {width: $account-list-collapsed-width;}
    to {width: $account-list-full-width;}
  }
  @keyframes drawer-collapse {
    from {width: $account-list-full-width;}
    to {width: $account-list-collapsed-width;}
  }

  .search-and-assignment--collapsed {
    display: flex;
    flex-direction: column;
    padding: 0;

    &__search-section {
      padding: 0 2em 0 0;
      width: 100%;

      &__input-and-filters {
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
        padding-right: 1em;

        &__account-search-inputs {
          flex-direction: column;
          width: 80%;

          .ordo-multi-select {
            margin-left: 1em;
            font-size: 0.9rem;
            height: 1.5em;
          }

          .ordo-input {
            width: 100%;
          }
        }
      }

      &__action-buttons {
        display: flex;
        justify-content: center;
        margin-bottom: 0.5em;
      }
    }

    .search-and-assignment__assignment-section {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: .5em;

      .assign-to-rep-button {
        display: flex;
        justify-content: center;
        button {
          width: 100%;
        }
      }
    }
  }

  .search-and-assignment {
    display: flex;
    flex-direction: column;

    &__search-section {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 4em;

      &__input-and-filters {
        display: flex;
        flex-direction: column;

        &__account-search-inputs {
          display: flex;
          padding-left: 1.5em;
          align-items: baseline;

          .ordo-multi-select {
            margin-left: 1em;
            font-size: 0.9rem;
            height: 1.5em;
            width: 50%;
          }

          .ordo-input {
            width: 50%;
            input {
              height: 2.5em !important;
            }
          }
        }
      }

      &__action-buttons {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 0.5em;
      }
    }

    &__assignment-section {
      display: flex;
      flex-direction: column;
      margin-top: -1.5em;
      margin-bottom: 1em;
      width: 25%;

      .assign-to-rep-button {
        margin: .5em 0;
        display: flex;
        justify-content: center;
      }
    }
  }

  .selected-account-count {
    text-align: center;
    margin-top: 0.5em;
    font-size: 0.7rem;
  }
  .account-filters-section {
    display: flex;
    align-items: baseline;
    margin-left: 22em;

    &__filters {
      display: flex;
      flex-direction: column;

      &__group {
        display: flex;
        flex-direction: row;
      }
    }
  }

  .account-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .card {
      height: 5.5em;
      margin: 0.4em;
      border-radius: $border-radius;
      padding: 0 0.2em;
      outline: none;

      &.already-assigned {
        pointer-events: none;
        opacity: 0.5;
      }

      .card-body {
        vertical-align: center;
        padding: 1em;
      }

      p {
        font-size: 0.7rem;
        line-height: 14px;
        margin-bottom: 0;
      }
      &:hover{
        cursor: pointer;
        background-color: #F8FAFD;
      }
      &.selected {
        border: 1px solid #62BC9F;
      }
    }
  }
}
