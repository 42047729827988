@import "common";

.multi-selector-with-tags {

  .ordo-searchable-dropdown-container {
    box-shadow: none;
    border: none;
    height: 2em;
  }

  &__selected {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    & > .clickable-tag {
      margin-right: 1em;
      margin-bottom: .5em;
    }

    .show-toggle {
      height: 2em;
      display: flex;
      align-items: flex-end;
      font-size: small;
      font-weight: 600;
      color: $primary;
      cursor: pointer;
    }
  }
}