@import 'common';
@import '../custom/styles';

.overlay-container {
  position: fixed;
  z-index: $Z_INDEX_MAX - 2;
  left: $sidebar-width;
  top: 0;

  width: 100% !important;
  height: 100%;

  @include blur-page;

  .ordo-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 75%;
    width: calc(100% - #{$sidebar-width});

    color: $ocean-green;

    &__call-to-action {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      font-weight: bold;

      h4 {
        color: $primary;
        font-weight: bold;
      }
    }
  }
}

.overlay-content-container {
  @include not-allow-select-text;
  @include not-allow-clicks;
}
