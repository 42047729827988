@import '../../custom/styles';
@import '../common';

.activity-row {
  display: flex;
  flex-direction: column;
  color: $dark;
  margin-bottom: 1em;
  width: 100%;
  padding: 0 2em;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0.5em;

    &__info {
      display: flex;
      flex-direction: column;

      &__checked {
        display: flex;

        .gmail-icon {
          height: 1em;
          width: auto;
          margin-top: 0.25em;
        }
      }

      &__subject {
        font-weight: bold;
        color: $primary;
        margin-bottom: 0.25em;
        margin-left: .5em;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 35ch;
      }

      &__subtitle-info {
        display: flex;
        color: $dark-purple;
        font-size: 0.9em;
        line-height: 1.25em;

        &__date {
          margin-right: .3em;
        }

        &__subtitle {
          display: flex;
          flex-direction: row;

          &__type {
            margin-left: .3em;
            margin-right: .3em;
          }

          .contacts-info {
            display: flex;
            align-items: center;

            .number-of-contacts {
              margin-left: .3em;
            }
          }
        }
      }
    }

    &__editing {
      color: $primary;
      display: flex;
      align-items: center;

      &__icon {
        cursor: pointer;
      }
    }
  }


  &__description {
    width: 100%;
    line-height: 1.5;
    height: fit-content;
    white-space:pre-wrap;
    color: $black;
    font-weight: 400;
    font-family: $font-family-base;
  }

  @media (max-width: $mobile-breakpoint) {
    width: $mobile-row-width;
    padding-left: 0;
    padding-right: 0;
  }
}
