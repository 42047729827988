@import '../custom/styles';
@import 'common';

//couldn't find a standard number for 2k resolution screens but this should cover all of them
$wide-screen-breakpoint: 2000px;

.right {
  border-top-left-radius: $border-radius;
  border-bottom-left-radius: $border-radius;
  right: 0;

  @keyframes drawer-slidein {
    from {transform: translateX(100%);}
    to {transform: translateX(0);}
  }
  @keyframes drawer-slideout {
    from {transform: translateX(0%);}
    to {transform: translateX(100%);}
  }
}
.drawer-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  -ms-overflow-x: hidden;
}

.drawer {
  @include ordo-shadow;
  background-color: white;
  position: absolute;
  min-height: 100%;
  width: 51vw;
  z-index: $Z_INDEX_MAX;
  display: flex;
  flex-direction: column;
  padding: 0;
  @media (min-width: $wide-screen-breakpoint) {
    width: 35vw;
  }
  .close-icon {
    color: $primary;
    font-size: 1.5em;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    padding: .5em .5em 3em 1em;
  }

  @media (max-width: $mobile-breakpoint) {
    width: 100%;
    max-width: 100%;
  }
}


