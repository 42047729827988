.subscription-step{
  width: 800px;
  $length: 75%;
  .registration-footer {
    display: flex;
    align-items: center;

    .registration-button {
    width: $length;
    margin-right: 0;
    }

    .back-and-create-buttons{
      width: $length;

      .registration-button {
        width: 100%;
      }
    }
  }
}
