@import '../purpose/variables';
@import '../ordo/common';

$arrow-size: 2em;
$zindex: 1;


.masked-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @include text-style;

  &__label {
    line-height: 1;
    margin-left: 1em;
    margin-bottom: -0.5em;
    padding:0 0.2em;
    max-width: min-content;
    font-weight: 600;
    color: $primary;
    background-color: $white;
    z-index: $zindex;
    font-size: xx-small;

    &.is-invalid {
      color: $danger;
    }
  }

  &__input {
    position: relative;

    input, input:focus {
      padding-left: 1em;
      padding-right: $arrow-size;
      width: $input-width;
      height: $arrow-size;
      border-radius: $border-radius;
    }

    &__icons {
      position: absolute;
      right: 0;
      top: 0;

      display: flex;
      z-index: $zindex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-left: -$arrow-size;
      width: $arrow-size;
      height: $arrow-size;
      color: $white;
      background-color: $primary;
      border-radius: $border-radius;

      &:hover {
        background-color: $primary-darkest;
        border-radius: $primary-darkest;
      }

      &.is-invalid {
        background-color: $danger;

        &:hover {
          background-color: $danger-darkest;
          border-radius: $danger-darkest;
        }
      }
    }
  }

  &__invalid-feedback {
    display: block;
    font-size: $font-size-tiny;
    line-height: 1;
    height: 0.5em;
    padding-left: 1em;
  }
}

.is-invalid {

  &__label {
    color: $danger;
  }

  &__input {
    input, input:focus {
      border: 2px $danger solid;
    }

    &__icons {
      color: $white;
      background-color: $danger;
      border: 2px $danger solid;
    }
  }
}

/* override default behaviour of the input, like the arrows and the outline when focus */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input:focus {
  outline:none;
}
input {
  -moz-appearance:textfield !important;
}



// custom icons

.price-icon {
  height: 0.75em;
  width:100%;
}
.percentage-icon {
  height: 0.75em;
  width:100%;
}
.switchable-icon {
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
}

