@import '../../purpose/variables';
@import '../../custom/variables';
@import '../common';

.filters-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
  border-bottom: 2px solid $primary;

  &__title {
    font-size: medium;
    font-weight: bold;
    color: $dark-purple;
  }

  &__close-icon {
    color: $pastel-red;
  }
}

.filters-content {
  padding: 1.5em 3em;
  font-weight: 700;
  color: $gray-900;
  font-size: medium;
  background-color: $background-light-gray;
}

.filters {
  margin-bottom: 1em;

  .filter-checkboxes {
    margin-top: 0.5em;
    margin-left: 1em;

    .custom-control-input-green:not(:checked) ~ .custom-control-label::before {
      border-color: $primary !important;
      border: solid 2px;
    }

    .custom-control-input-green:checked ~ .custom-control-label::before {
      border-color: $primary !important;
      border: solid 2px;
      background-color: $primary;
    }

    .cursor-pointer {
      cursor: pointer;
    }

    .unselected-text {
      font-weight: 500;
    }
  }
}
