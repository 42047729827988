
// Product quick view 

.quick-view-container {
    background: rgba(10, 10, 10, .85);
}

.quick-view-content {
    bottom: 0;
    height: calc(100% - 40px);
    left: 0;
    margin: auto;
    max-height: 650px;
    max-width: 980px;
    position: absolute;
    right: 0;
    top: 0;
    width: calc(100% - 40px);
}

.quick-view-carousel {
    background: $card-bg;
    border: 1px solid $card-border-color;
    border-radius: $card-border-radius;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 57%;
    z-index: 10;

    @include box-shadow($box-shadow);

    .fancybox-stage {
        bottom: 30px;
    }
}

.quick-view-aside {
    background: $card-bg;
    border-top-right-radius: $card-border-radius;
    border-bottom-right-radius: $card-border-radius;
    bottom: 2rem;
    color: $card-color;
    left: auto;
    padding: 3rem 0 2rem 0;
    position: absolute;
    right: 0;
    top: 2rem;
    width: 43%;

    // &:before,
    // &:after {
    //     bottom: 0;
    //     content: '';
    //     left: 0;
    //     position: absolute;
    //     top: 0;
    // }

    // &:before {
    //     background: #f4f4f4;
    //     width: 8px;
    // }

    // &:after {
    //     background: #e9e9e9;
    //     width: 1px;
    // }

    > div {
        height: 100%;
        overflow: auto;
        padding: 0 2rem;
    }
}

.quick-view-close {
    background: #f0f0f0;
    border: 0;
    border-top-right-radius: $card-border-radius;
    border-bottom-left-radius: $card-border-radius;
    color: #222;
    cursor: pointer;
    font-family: Arial;
    font-size: 14px;
    height: 44px;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
    text-indent: -99999px;
    top: 2rem;
    transition: all .2s;
    width: 2.75rem;

    &:hover {
        background: #e4e4e4;
    }

    &:before,
    &:after {
        background-color: #222;
        content: '';
        height: 18px;
        left: 22px;
        position: absolute;
        top: 12px;
        width: 1px;
    }

    &:before {
        -webkit-transform: rotate(-45deg);
           -moz-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
             -o-transform: rotate(-45deg);
                transform: rotate(-45deg);
    }

    &:after {
        -webkit-transform: rotate(45deg);
           -moz-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
             -o-transform: rotate(45deg);
                transform: rotate(45deg);
    }
}

.quick-view-bullets {
    bottom: 0;
    left: 0;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    text-align: center;
    width: 100%;
    z-index: 99999;

    li {
        display: inline-block;
        vertical-align: top;

        a {
            display: block;
            height: 30px;
            position: relative;
            width: 20px;

            span {
                background: $pagination-bullet-bg;
                border-radius: 99px;
                height: 10px;
                left: 50%;
                overflow: hidden;
                position: absolute;
                text-indent: -99999px;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 10px;
            }
        }

        &.active a span {
            background: $pagination-bullet-active-bg;
        }
    }
}
