@import '../purpose/variables';
@import 'common';

$ordo-input-height: 2em;

.ordo-input-extra-short {
  width: 20%;
}

.ordo-input-short {
  width: 25%;
}

.ordo-input-medium {
  width: 50%;
}

.ordo-input-semi-large {
  width: 75%;
}

.ordo-input-large {
  width: 100%;
}

.ordo-input {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;

  label {
    width: 100%;
  }

  &__title {
    font-weight: bold;
    color: $black;
  }

  .form-control {
    border-radius: 15px !important;
    height: $ordo-input-height !important;
  }

  .invalid-feedback {
    display: block;
  }


  input::placeholder {
    font-weight: 400;
    font-size: .9em;
  }

  &__input {
    font-size: $font-size-base;
    font-weight: 600;
    color: $dark;
    @media (max-width: $mobile-breakpoint) {
      // This is to prevent zoom in on the text input on mobile devices
      font-size: clamp(16px, #{$font-size-base}, #{$font-size-base}) !important;
    }
  }
}

.no-spin::-webkit-inner-spin-button, .no-spin::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

.no-spin {
  -moz-appearance:textfield !important;
}

.ordo-input-container {
  display: flex;
  width: 100%;
  justify-content: center;

  .ordo-input-icon {
    height: $ordo-input-height;
    left: 1em;
    position: absolute;
  }

  .form-control {
    padding-left: 2.5em;
  }
}

.is-invalid {
  input, input:focus {
    border: 2px $danger solid !important;
  }

  label {
    color: $danger !important;
  }
}

@media (max-width: $mobile-breakpoint) {
  .ordo-input-large, .ordo-input-semi-large, .ordo-input-medium {
    width: 100%;
  }
}
