@import '../custom/variables';
@import '../purpose/variables';
@import '../ordo/common';

.dropdown-label {
  line-height: 1;
  margin-left: 1em;
  top: -.5em;
  //margin-bottom: -0.5em;
  padding:0 0.5em;
  max-width: min-content;
  font-weight: 600;
  color: $primary;
  background-color: $white;
  z-index: 10;
  font-size: xx-small;
  position: absolute;
  white-space: nowrap;
}

.ordo-searchable-dropdown-container {
  border-color: transparent;
  border-radius: $border-radius;
  @include ordo-shadow;
  @include ordo-card-border;
  position: relative;
}

.option {
  width: 90%;
  display: flex;
  flex-direction: column;

  &:hover {
    color: $dark
  }

  &__title {
    display: flex;
    justify-content: space-between;
    font-weight: $font-weight-bold;
    font-size: $font-size-big;

    &__disabled-text {
      font-size: 0.8em;
      padding-top: 1em;
      line-height: 1;
      padding-right: 2em;
      color: $gray-700;
    }
  }

  &__info {
    display: flex;
    font-size: $font-size-base;
    line-height: 1;

    &__sub-title {
      font-weight: bold;
      margin-right: 1em;
    }

    &__info {
      overflow: hidden;
      text-overflow: ellipsis;
      padding-bottom: 0.3em;
    }
  }
}
.selected-option {
  color: $dark;
}

.not-selected-option {
  width: 100%;
  color: gray;

  &.disabled-option {
    cursor:auto;
    color: $gray-600;
    &:hover {
      color: $gray-600;
    }
  }
}
.menu-option {
  padding: 0.5em 1em;
  cursor: pointer;
  &:hover {
    background-color: whitesmoke;
  }
}

.add-item-option-container {
  height: 3em;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: $primary;
    color: white;

    span, svg {
      color: white;
    };
  }

  &.option-disabled:hover {
    background-color: $gray-300;
    color: black;

    span, svg {
      color: $gray-700;
    };
  }
}
