@import '../../purpose/variables';

.product-name-to-delete {
  font-size: initial;
  color: $gray-800;
}

.confirmation-message {
  color: $dark;
  line-height: 1.3;
}
