@import 'common';

.brand-card {

  max-width: 12%;
  min-width: 12%;
  margin-right: 1em;

  &__name {
    font-size: $font-size-base;
    margin-top: .5em;
    text-align: center;
    word-wrap: break-word;
    word-break: break-word;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6; /* number of lines to show */
    -webkit-box-orient: vertical;
    line-height: 1.2;
    color: $primary;
  }
}

.image-preview-container {
  border: 1px solid $brand-logo-border;

  &__image {
    object-fit: fill;
  }
}
