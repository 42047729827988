@import '../../custom/variables';
@import '../../ordo/common';

.assign-account-dialog {
  max-width: 90vw;
}

.account-modal {
  svg {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .selected-account-card {
    margin: 10px;
    border-radius: $border-radius;
  }

  .card {
    height: 5.75em;
    margin: 0.5em;
    border-radius: $border-radius;
    padding: 0.5em;
    outline: none;

    .card-body {
      vertical-align: center;
      padding: 0.5em;

      .account-name {
        color: $gray-800;
        font-size: smaller;
        line-height: 1.2;
        font-weight: bold;
      }
    }

    p {
      font-size: 0.6rem;
      line-height: 14px;
      margin-bottom: 0;
    }
  }

  .brand-logo-container {
    display: inline-block;
    width: auto;
    outline: none;
    border-radius: 5px;
    margin: 5px;
    box-shadow: 1px 1px 1px 1px #F8FAFD;
    border: 1px solid transparent;

    &.selected {
      border: 1px solid #62BC9F;
    }

    &:hover{
      cursor: pointer;
      opacity: 0.8;
    }

    img {
      height: 70px;
      width: 70px;
      border-radius: 5px;
    }
  }
}
