@import './common';

.emails {
  height: fit-content;
  width: 100%;

  &.hide-thread {
    white-space: pre-wrap;
    max-height: 4.5em;
    overflow: hidden;
  }
  .email {
    .account-contact-email {
      color: $dark-purple;
      font-weight: bold;
    }

    .body {
      white-space: pre-wrap;
      line-height: 1.5;
      font-weight: 400;
      font-family: $font-family-base;
    }
  }

}

.thread-toggle {
  color: $primary;
  cursor: pointer;
  display: flex;
  justify-content: center;
}
