@import '../../ordo/common';

.square-payment-input {
  margin-bottom: 2em;
}

.card-input-hidden {
  display: none;
}

.card-input-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .ordo-button {
    margin-top: -3.25em;
  }
}