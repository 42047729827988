@import '../common';

.ordo-main-content-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;

  .scrollable-content {
    width: 100%;
    height: 100%;

    display: flex;
    align-items: flex-start;
    justify-content: center;
    overflow-y: scroll;
  }

  .main-content {
    // TODO: OR-661: main-content class has a lot of inherited code from bootstrap,
    //  we may use a custom one so we have more control over it.
    max-width: calc(#{$page-content-width} - #{$sidebar-width} - #{$sidebar-margin});
    height: min-content;
    min-height: 100%;
  }

  .wider-main-content {
    // just having a different name that main-content makes it wider because all bootstrap
    // classes that work for .main-content doesn't work here.
    max-width: calc(#{$page-content-width} - #{$sidebar-width} - #{$sidebar-margin});
  }
}

.page-with-sidebar {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.ordo-application {
  opacity: 0;

  &.ready {
    opacity: 1;
  }

  .ordo-container-application {
    //max-width: 1420px;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 0;
    padding-right: 0;
  }

  .navbar {
    margin-bottom: 1.5rem;
    padding-left: 0;
    padding-right: 0;

    .container,
    .container-fluid {
      width: calc(100% + #{$grid-gutter-width});
      padding-left: 0;
      padding-right: 0;
    }
  }

  .navbar-collapse {
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
  }

  // Main content

  .main-content {
    width: 100%;
    overflow: hidden;

    .container,
    .container-fluid {
      position: relative;
    }
  }

  .page-title {
    +.row,
    +.card {
      margin-top: 1.5rem;
    }
  }

  // Sidenav

  .sidenav {
    width: 4.5em;
    min-width: 32px;
    display: flex;
    justify-content: center;

    &.show {
      display: block;
      margin-left: $grid-gutter-width * 2;
      width: $navbar-vertical-open-width;
      min-width: $navbar-vertical-open-width;
    }

    .nav-application {
      margin-top: 50px;
    }
  }

  // Sidenav header

  .sidenav-header {
    padding: 1.5rem 0;
    margin-bottom: 1.5rem;

    .navbar-brand {
      img {
        height: 1.5rem;
      }
    }
  }

  // Sidenav user

  .sidenav-user {
    height: 290px;
  }

  // Page title

  .page-title {
    + .nav-tabs {
      margin-bottom: $grid-gutter-width;
    }
  }
}

.ordo-application .ordo-container-application {
  height: 100vh;
}

.ordo-application-offset {
  .ordo-container-application {
  }
}

.ordo-application-full-page {
  opacity: 0;

  &.ready {
    opacity: 1;
  }

  overflow: hidden;
}

// TODO: OR-661 clean-up this mixins.
.block-application {
  @include blur-page;
  @include not-allow-select-text;
  @include not-allow-clicks;
}
