@import 'common';

@mixin headerContainerStyling {
  height: $header-height;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .general-ordo-logo {
    margin-left: 1rem;
  }

}

.general-header-container {
  @include headerContainerStyling;
  padding-right: 1em;

  // Had to put this this way because spinner was overriding the styling. Is there a better way?
  .spinner-content-container{
    @include  headerContainerStyling;
  }

  .ordo-searchable-dropdown-container{
    width: fit-content;
    border: none;
    box-shadow: none;

    input {
      caret-color: transparent;
    }

    .option__title {
      white-space: nowrap;
      font-weight: 200;
      font-size: $font-size-small;
    }

    .option__info {
      white-space: nowrap;
      font-weight: 400;
      font-size: $font-size-small;
    }

    // This basically overrides <Select> box-shadows by diving into it's only nested div. TODO: find a better way
    div {
      background: whitesmoke;
      border-radius: 0;
      div {
        box-shadow: none;
        svg {
          color: $primary;
        }
        span {
          display: none;
        }
      }
    }
  }
}

