@import '../purpose/variables';
@import '../ordo/common';

.ordo-primary-button {
}

.btn-inner--text {
  font-size: $font-size-sm;
}

.ordo-button-reduced-padding {
  &.btn {
    width: fit-content;
    padding: 0.2em 2.5em;
  }
}

.ordo-cancel-button {
  background-color: transparent;
  border: 2px solid $primary;
}

.ordo-secondary-button {
  background-color: transparent !important;
  border: 2px solid $primary !important;
  color: $primary !important;

  &:hover {
    background-color: whitesmoke !important;
    border: 2px solid $success !important;
    color: $success !important;
  }
}

.ordo-transparent-button {
  background-color: transparent;
}

.ordo-button-short {
  width: 25%;
}

.ordo-button-semi-short {
  width: 40%;
}

.ordo-button-medium {
  width: 50%;
}

.ordo-button-large {
  width: 100%;
}


.ordo-little-button {
  padding: 0 .5em !important;
  display: flex !important;
  height: fit-content;

  .little-button-text {
    font-size: xx-small;
  }
}

.button-content {
  display: flex;
  align-items: center;
  justify-content: center;
}


@media (max-width: $mobile-breakpoint) {
  .ordo-button-reduced-padding {
    width: 100%!important;
  }
}
