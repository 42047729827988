@import '../custom/variables';
@import '../custom/styles';
@import 'common';

.product-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .product-price {
    @include text-style;
    font-weight: 600;
  }

  .product-preview__item-image-and-name {

    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 63%;

    &__name {
      display: flex;
      flex-direction: column;

      .product-name {
        @include text-style;
        margin-left: 1rem;
        font-weight: bold;

        width : 70%;
        display:inline-block;
        white-space: normal;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .product-category-size {
        @include text-style;
        margin-left: 1rem;
        max-width: 15ch;
        overflow:hidden;
        text-overflow: ellipsis;
      }
    }

    .product-image {
      @include ordo-shadow;

      width: 5em;
      height: 5em;
      border-radius: 10px;
    }
  }
}

.product-header-for-allocations {
  width: 100%;
  display: flex;
  align-items: flex-start;

  .item-image-and-info {

    display: flex;
    justify-content: flex-start;
    align-items: center;

    &__info {
      display: flex;
      flex-direction: column;

      width: 100%;

      .product-name {
        text-overflow: ellipsis;
        white-space: nowrap;
        color: $black;
        margin-left: 1rem;
        font-weight: bold;
        width : 70%;
        overflow:hidden;
        display:inline-block;
      }

      .product-info {
        font-size: $font-size-xs !important;
        @include text-style;
        margin-left: 1rem;
        max-width: 15ch;
        overflow:hidden;
        text-overflow: ellipsis;
      }
    }

    $image-size: 5em;
    .product-image {
      width: $image-size;
      height: $image-size;
      border-radius: 10px;
    }
  }

  .allocations-modal__total-stock {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
    white-space: nowrap;
    color: $gray-800;
    margin-right: 1em;

    &__title {
      font-size: $font-size-base;
    }

    &__value {
      font-size: $font-size-lg;
      font-weight: bold;
      color: $ocean-green;
    }
  }
}

.only-unit-tag {
  background-color: $dark-purple;
  width: fit-content;
  padding: 0 0.5em;
  font-weight: bold;
  color: white;
  border-radius: 4px;
  margin-top: 0.2em;
}

.product-size {
  @include text-style;
  font-size: $font-size-sm;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.units {
    margin-top: 1.4em;
  }

  &.cases {
    margin-top: 1.5em;

    &.cases-only {
      margin-top: 0;
    }
  }


  .amount-of-cases {
    font-size: .6rem;
  }
}

