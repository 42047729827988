@import '../../purpose/variables';
@import '../../custom/variables';
@import '../common';

$colored-border: 3px solid $primary;
$pipeline-card-width: 13.5em;

.sales-activity-page {
  height: min-content;
  width: min-content;
  @media (max-width: $mobile-breakpoint) {
    width: 100%;
    padding-right: $sidebar-width - .5em;
  }
}

.sales-activity-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .track-order-button-container {
    margin-right: 1em;
    position: fixed;
    right: 1em;

    .ordo-little-button {
      padding: 0.5em;
      .little-button-text {
        font-size: .8em;
      }
    }
  }

  @media (max-width: $mobile-breakpoint) {
    .ordo-page-title {
      margin: 0;
    }
  }
}

.sales-activity-filters {
  display: flex;
  flex-direction: row;
  align-items: center;

  .sorting-date-filter {
    display: flex;
    align-items: center;
    cursor: pointer;

    .sort-icon {
      height: 2em;
      width: 1.5em;
      color: $gray-900;
      margin-right: .5em;
      cursor: pointer;
      transform: rotate(90deg);
    }
  }

  .search-by-account-input {
    margin-right: 2em;
    width: 16%;
    min-width: 12em;
    margin-bottom: 0.4em;
    border-radius: 2em;
    box-shadow: 0 0 0.4em 0.1em $brand-logo-border;
    border: 0.1em solid transparent;
    font-weight: initial;
    font-size: 0.9em;
    padding: 0.54rem 1rem;
    color: gray;
  }
}

.sales-activity-page-container {
  width: min-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 1em;
  background-color: transparent;
  border-radius: $border-radius;

  .sales-activity-pipeline-headers {
    width: 100%;
    margin-top: 1em;
    border-radius: $border-radius;
    display: flex;
    justify-content: space-between;
    padding-left: 1%;
    padding-bottom: .7em;
    padding-top: .7em;
    background-color: white;

    .sales-activity-pipeline-header {
      display: flex;
      flex-direction: column;
      font-weight: bold;
      margin-right: 1em;

      .title {
        color: $primary;
      }

      .subtitle {
        color: $black;
        font-size: x-small;
        line-height: 1;
      }
    }
  }
  .sales-activity-pipeline-header:last-child {
    margin-right: 0;
  }

  .sales-activity-pipeline-card-container {
    width: 100%;
    margin-bottom: 1em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .pipeline-cards-column {
      margin-right: 1em;

      .pipeline-card {
        padding: .7em .7em;
        width: 100%;
        min-width: $pipeline-card-width;
        background-color: white;
        border-radius: $border-radius;
        color: $black;
        margin: 0.5em 0;
        cursor: pointer;

        .pipeline-card-upper, .pipeline-card-bottom {
          display: flex;
          flex-direction: column;

          .name-and-address {
            width: 80%;
            display: flex;
            flex-direction: column;
          }

          .name {
            font-size: smaller;
            font-weight: bold;
          }

          .make-text-red {
            color: $warning_color;
          }

          .address {
            font-size: xx-small;
            line-height: 1;
          }

          .priority-icon {
            height: 1.5em;
            width: 1.5em;
          }

          .priority-icon:hover {
            cursor: pointer;
          }
        }

        .pipeline-card-upper {
          margin-bottom: 1em;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
        }

        .pipeline-card-bottom {
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          justify-content: space-between;
          position: relative;

          .text-section {
            width: min-content;
            display: flex;
            flex-direction: column;
          }

          .card-button {
            height: 10%;
            border-radius: 7px;
            border-color: transparent;
            background: $primary;
            color: white;
          }
        }
      }
    }
    .pipeline-cards-column:last-child {
      margin-right: 0;
    }
  }
}

.mobile-pipeline-content {

  .sales-activity-filters {
    margin-bottom: 1.5em;

    .search-by-account-input {
      width: 80%;
      margin: 0 0.75em 0 0;
    }
    .sorting-text {
      margin-left: -0.25em;
      width: 2.5em;
    }
  }

  .sales-activity-page-container {
    margin-top: 0;
    width: 100%;

    .sales-activity-pipeline-mobile-header {
      display: flex;
      margin-top: 0;
      margin-bottom: 1em;
      justify-content: space-between;

      .header-navigation {
        color: $primary;
        display: flex;
        align-items: center;
        font-size: 2em;
        cursor: pointer;
        position: absolute;
        padding: 5em .5em 5em ;
      }

      .navigate-back {
        padding-left: .75em;
        left: 0;
      }
      .navigate-forward {
        padding-right: .75em;
        right: 0;
      }

      .sales-activity-pipeline-header {
        width: 100%;
        display: flex;
        flex-direction: column;
        font-weight: bold;
        margin-left: 1em;

        .title {
          color: $primary;
        }

        .subtitle {
          color: $black;
          font-size: x-small;
          line-height: 1;
        }
      }
    }

    .pipeline-cards-column {
      width: 100%;

      .pipeline-card-bottom {
        .ordo-little-button {
          padding: 0.5em;
          .little-button-text {
            font-size: .8em;
          }
        }
      }
    }
  }
}

.order-sales-activity-filters {
  display: flex;
  flex-direction: row;
  align-items: center;

  .search-order-input {
    margin-right: 2em;
    width: 10%;
    min-width: 12em;
    height: 2.7em;
    margin-bottom: 0.4em;
    border-radius: 2em;
    box-shadow: 0 0 0.4em 0.1em $brand-logo-border;
    border: 0.1em solid transparent;
    font-weight: initial;
    font-size: $font-size-big;
    padding-left: 0.5rem;
    color: gray;
  }
}

.no-orders-content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
  color: $gray-900;
}
.greyed-out {
  color: $gray-700;
}

.regular-color {
  color: $black;
}
