@import 'common';

.ordo-checkbox-container {
  display: flex;
  flex-direction: column;

  .ordo-checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;

    &__label {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: $primary;

      &__checkbox {
        margin-right: 0.35em;
      }
    }
  }
}


/* Hide the default checkbox */
input[type=checkbox] {
  visibility: hidden;
}

.ordo-checkbox__label {
  display: block;
  position: relative;
  cursor: pointer;
  color: $primary;
}

/* Creating a custom checkbox based on demand */
.ordo-checkbox__label__span {
  position: absolute;
  top: 3px;
  left: 0;

  height: 1.2em;
  width: 1.2em;

  border: 1px solid $primary;
  border-radius: 3px;
  background-color: transparent;
}

/* Specify the background color to be shown when hovering over checkbox */
.ordo-checkbox__label:hover input ~ .ordo-checkbox__label__span {
}

/* Specify the background color to be shown when checkbox is active */
.ordo-checkbox__label input:active ~ .ordo-checkbox__label__span {
}

/* Specify the background color to be shown when checkbox is checked */
.ordo-checkbox__label input:checked ~ .ordo-checkbox__label__span {
  background-color: $primary;
}


/* Checkmark to be shown in checkbox */
/* It is not be shown when not checked */
.ordo-checkbox__label__span:after {
  content: "";
  position: absolute;
  display: none;
}

/* Display checkmark when checked */
.ordo-checkbox__label input:checked ~ .ordo-checkbox__label__span:after {
  display: block;
}

/* Styling the checkmark using webkit */
/* Rotated the rectangle by 45 degree and showing only two border to make it look like a tickmark */
.ordo-checkbox__label .ordo-checkbox__label__span:after {
  left: 25%;
  bottom: 16%;

  width: 45%;
  height: 80%;

  border-radius: 25%;
  border: solid white;
  border-width: 0 3px 3px 0;

  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
