@import '../../custom/styles';
@import '../common';


.account-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: $black;
  padding: 1em;
  height: 8.5em;
  width: 100%;

  &__account-personal-info {

    &__name {
      font-weight: 800;
      font-size: 1.5em;
      cursor: pointer;
      text-overflow: ellipsis;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
    }

    &__info {
      display: flex;
      font-size: $font-size-base;
      line-height: 1;

      &__license {
        font-weight: 600;
        margin-right: 1em;
      }

      &__address {
        overflow: hidden;
        text-overflow: ellipsis;
        padding-bottom: 0.3em;
      }

      &__stale-period {
        display: flex;
        align-items: center;
        padding: 0.25em;
        height: 2.5em;

        &__conversion {
          display: flex;
          align-items: center;
          @media (max-width: $mobile-breakpoint) {
            width: 52%;
          }
          &__text {
            color: $primary;
            font-weight: 600;
            margin-right: .5em;
          }

          &__input {
            margin-top: .5em;
            margin-right: 1em;
            label {
              width: 8em;
            }
          }

          input {
            padding: 0 .9em;
          }
        }
      }
    }
  }

  &__priority-icon {
    height: 2em;
    width: 2em;
    margin-right: 3em;
  }

  &__priority-icon:hover {
    cursor: pointer;
  }
}

.account-details {
  padding: 1em;
  margin: 0 1.5em;
  @include ordo-shadow;
  @include rounded-border;
  @include ordo-card-border;

  &__buttons {
    display: flex;
    width: 100%;
    justify-content: center;
    @media (max-width: $mobile-breakpoint) {
      margin-top: 1em;
    }
  }
}

.verified-account {
  @media (max-width: $mobile-breakpoint) {
    width: 100%;

    .account-header {
      &__priority-icon {
        margin-right: 3em;
        cursor: pointer;
      }
    }
  }
}
