//
// Badge floating
//


.btn {
    .badge-floating {
        position: absolute;
        top: -50%;
        right: 0;
        transform: translate(50%, 50%);
        margin: 0;
        border: 3px solid;
    }
}
