.product-form-fields-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1.5em;
  align-items: baseline;

  &__image-and-preview {
    width: 45%;
    display: flex;
    flex-direction: row;
  }

  &__field {
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    &__image {
      display: flex;
      flex-direction: column;
    }

    &__image-preview {
      margin-top: 1.5em;
    }

    &__brands {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      &__button {
        background-color: transparent;
        border: none;
        margin-right: 1em;
        margin-bottom: 1em;
        padding: 0;
      }
    }

    &__consumer-unit-size {
      display: flex;
      flex-direction: row;

      &__dropdown {
        width: 50%;
        margin-left: 0.5em;
      }
    }

    &__product-SKU {
      display: flex;
      flex-direction: column;
    }
  }
}
