@import '../custom/variables';
@import 'common';

@mixin cell_padding {
  padding: 1.2em 0 0.3em 0.7em !important;
}

td:last-child, th:last-child {
  padding-right: 1.25em !important;
}
td:first-child, th:first-child,  {
  padding-left: 1.25em !important;
}
.ordo-table {
  white-space: normal;
  width: 100%;
  color: $black;
  table-layout: auto;
  overflow-wrap: break-word;
  font-weight: 500;
  max-width: 100%;
  border-collapse: collapse;

  &.remove-borders {
    table, tr, td, th {
      border: none !important;
    }
  }

  &__headers {
    color: $gray-900;

    &.with-border-bottom {
      th {
        font-weight: 700;
        font-size: $font-size-base;
        border-top: none;
        padding: 0.5em !important;
        border-bottom: 1px solid $table-header-separator-color;
      }
    }

    &__header-title {
      @include cell_padding;
    }
  }

  &__cell {
    text-align: left;
    vertical-align: initial;
    white-space: nowrap;
    @include cell_padding;
    @include details-text-style;
  }
}

