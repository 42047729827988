@import '../../custom/styles';
@import '../common';


.order-details-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5em 24px;
  border-top: 2px solid $light-gray;
  width: 98%;
  margin: 0 0.5em 0 0.5em;

  .upper-section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: medium;

    .btn {
      width: 11.5em;
    }

    .order-details-row-header {
      display: flex;
      align-items: center;
      width: 100%;
    }

    .caret-and-title {
      cursor: pointer;

      .caret-icon {
        transition: transform 250ms ease;

        &.opened {
          transform: rotate(90deg);
        }

        &.closed {
          transform: rotate(0deg);
        }
      }

      &.opened {
        color: $primary;
      }
    }

    .details-title {
      margin-left: 1em;
    }

    @media (max-width: $mobile-breakpoint) {
      align-items: center;

      .btn {
        width: 6em;
      }
      .ordo-little-button {
        padding: 0.5em;
        white-space: nowrap;
        justify-content: center;

        .little-button-text {
          font-size: .8em;
        }
      }
    }
  }

  .bottom-section {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .add-form-container {
      display: flex;
      justify-content: center;
      margin: 1.5em 0;

      .add-form {
        width: 35em;
        padding: 1em;
        @include ordo-shadow;
        @include rounded-border;
        @include ordo-card-border;
      }
    }
  }
}

.order-summary-section {
  padding: 0 0 1.5em;
  .upper-section {
    padding: 1.5em 24px 0;
  }
}

.section-collapsed {
  display: flex;
  justify-content: center;
  height: 6.5em;
}

.section-expanded {
  border-top: 2px solid $primary;

  .upper-section {
    margin-bottom: 1.5em;
  }
}
