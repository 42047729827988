@import 'common';

.accept-invitation-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  &__card {
    min-width: 30%;
    min-height: 150px;
    padding: 16px;
    border-radius: $border-radius;
  }
}
