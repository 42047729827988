@import '../purpose/variables';
@import '../custom/variables';
@import '../ordo/common';


.ordo-datepicker-container {
  @include rounded-border;

  .rdtPicker {
    right: 0;
  }

  .selected-date-container {
    padding: 0.5em 1.1em;
  }

  .no-value-selected {
    padding-top: 1.15em;
  }

  .selected-day {
    font-weight: $font-weight-bold;
    font-size: $font-size-big;
    color: $dark;
    line-height: 1.5;
  }

  .selected-day-info {
    font-size: $font-size-base;
    color: $dark;
    line-height: 1;
    padding-bottom: 0.3em;
  }

  .not-day-selected {
    @include placeholder-text-style;
  }

  .rdtDay {
    color: $dark;
  }

  .rdtSwitch, .rdtPrev, .rdtNext {
    color: $dark;
  }

  .rdtToday {
    font-weight: 600;
  }

  .rdtTimeToggle {
    color: $dark;
  }
}

.card-display {
  border-radius: $border-radius;
  border: 1px solid #e9ecef;
}

.single-line-display {
  border: none;

  .displayed-time {
    display: flex;
    align-items: center;

    .selected-day-info {
      line-height: 1.5;
    }

    .placeholder {
      color: $light-gray;
    }
  }
}

.datepicker-icon {
  color: $primary;
  margin-right: 0.5em;
}
