@import '../../purpose/variables';
@import '../../custom/variables';
@import '../common';

@keyframes landscape-disabled-arrow-animation {
  0% {
    align-self: start;
    opacity: 0;
  }

  100% {
    align-self: end;
    opacity: 1;
  }
}

.landscape-disabled-message {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding-bottom: 3rem;
  padding-right: 4em;
  position: absolute;

  &__icons {
    display: flex;
    flex-direction: row;
    color: $primary;

    .arrow {
      margin-left: 0.25em;
      display: flex;
      align-self: start;
      animation: landscape-disabled-arrow-animation 1.5s cubic-bezier(0.09, 0.47, 0.98, 1.01) infinite;
    }
  }

  &__text {
    color: $black;
    font-size: $font-size-xl;
    font-weight: bold;
  }
}

.mobile-order-entry-container, .mobile-checkout-container {
  width: 100%;
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;

  .mobile-order-entry-header {
    padding: 0 1em;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 15%;

    &.landscape {
      height: 23%;
    }

    .ordo-searchable-dropdown-container {
      width: 88%;
    }

    &__contact-and-distributor, &__selector {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 3.75em;

      &.landscape {
        width: 100%;
        flex-direction: row;

        .ordo-searchable-dropdown-container {
          width: 46%;
        }
      }
    }

    &__contact-and-distributor {
      .ordo-searchable-dropdown-container {
        width: 49%;

        .option__title {
          font-size: 0.70rem;
          max-height: 2em;
          line-height: 1;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box !important;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          white-space: normal;
          margin-bottom: .2em;
          margin-top: .3em;
        }

        .option__info {
          font-size: 0.63rem;
        }
      }
    }

    &__selector {
      $selector-width: 88%;

      .select-delivery-date {
        display: flex;
        flex-direction: column;
        align-self: start;
        align-items: center;
        width: 12%;
        margin-top: 0.25em;

        .ordo-datepicker-container *.form-control {
          background-color: $background-light-gray;
          padding: 0 !important;

          svg {
            width: 2em;
            height: 2em;
            margin: 0;
          }
        }

        .selected-date-text {
          color: $primary;
          font-weight: bold;
          line-height: 1.1;
          padding-top: .2em;
          font-size: smaller;
        }
      }

      .ordo-searchable-dropdown-container {
        width: $selector-width;
        margin-bottom: 1em;


        .selected-option {
          width: 97%;
        }

        .option__title {
          font-size: 0.70rem;
          max-height: 2em;
          line-height: 1;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box !important;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          white-space: normal;
          margin-bottom: .2em;
          margin-top: .3em;
        }

        .option__info {
          font-size: 0.63rem;
        }
      }

      .date-icon {
        margin-bottom: 1em;
        color: $primary;
      }

      .cart-with-items {
        color: $primary;
        position: relative;
        margin-bottom: 1em;
        margin-top: .5em;

        &__number {
          position: absolute;
          z-index: 1;
          background-color: $background-light-gray;
          height: 1.5em;
          top: -0.15em;
          left: 0.7em;
          width: 2.5em;
          text-align: center;
          font-weight: bold;
          padding-right: 1em;

        }
      }

      .search-by-product-name {
        width: $selector-width;

        &.landscape {
          width: 94%;
        }
      }

      .filter-products-icon {
        color: $primary;
        width: 1em;
        height: 1em;
      }
    }
  }

  .back-icon {
    width: min-content;
    padding-left: 1em;
  }


  .products-container {
    width: 100%;
    height: 71%;
    max-height: 71%;
    margin-top: 0.25em;
    background-color: white;
    $table-header-height: 1.75em;

    &.landscape {
      height: 50%;
    }

    .products-table-header {
      height: $table-header-height;
      background-color: $primary;
      color: white;
      font-weight: bold;
      display: flex;
      font-size: 0.73rem;

      .product-name {
        width: 45%;
        padding-left: 1em;
      }
    }

    .products-list {
      $product-line-height: 6em;
      max-height: calc(100% - #{$table-header-height});
      overflow: scroll;

      .product-line {
        display: flex;
        align-items: center;
        padding: 0.25em 0.5em;
        background-color: white;
        border-bottom: 1px solid $light-gray;
        height: $product-line-height;

        &__info-unit-only-width {
          width: 70%;
        }

        &__info-width {
          width: 40%;
        }

        &__info {
          display: flex;

          .product-image {
            width: 2.5em;
            height: 2.5em;
            border-radius: 10px;
            margin-right: .5em;
          }

          .product-info {
            width: 85%;
            line-height: 1.3;
            flex-direction: column;
            color: black;
            font-size: smaller;

            .name {
              color: $dark-purple;
              font-weight: bold;
            }

            .info {
              text-overflow: ellipsis;
              overflow: hidden;
              display: -webkit-box !important;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              white-space: normal;
            }
          }
        }

        .product-pricing-width {
          width: 60%;
        }

        .product-pricing-unit-only-width {
          width: 30%;
        }

        .product-pricing {
          padding-left: 0.5em;
          display: flex;
          align-items: flex-end;
          height: 100%;

          .case-pricing, .unit-pricing {
            width: 50%;
          }

          .unit-only-unit-pricing, .case-only-case-pricing {
            width: 100%;
          }

          .case-pricing, .unit-pricing, .unit-only-unit-pricing, .case-only-case-pricing {
            height: 100%;
            line-height: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
            margin-right-right: 1em;

            &__text {
              display: flex;
              flex-direction: column;
              justify-content: center;
              height: 2.25em;
              align-self: flex-start;
              width: 100%;

              .price {
                display: flex;
                justify-content: center;
                font-size: small;
                color: black;
              }

              .size {
                display: flex;
                justify-content: center;
                font-size: xx-small;
                color: black;
              }
            }

            .mobile-add-to-cart {
              width: 7em;

              &.landscape {
                .add-to-cart {
                  width: 100%;

                  button {
                    width: inherit;
                  }
                }
              }

              .add-to-cart {
                height: 2em;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: baseline;
                justify-content: flex-end;

                .add-to-cart__input {
                  display: flex;
                  flex-direction: row;
                  justify-content: space-around;
                  width: 100%;
                }

                .number-input {
                  width: 4.5em;
                  padding-left: 0.50em;
                  padding-right: 0.75em;
                }
              }
            }

            .product-info {
              width: 100%;
              display: flex;
              justify-content: center;
              padding-top: 0.1em;
              font-size: xx-small;
              color: black;
            }
          }
        }
      }

      .product-line:last-child {
        border-bottom: none;
      }
    }
  }
}

.mobile-checkout-container, .mobile-summary-container {
  background-color: white;
  top: 0;
  z-index: 10000000;
  height: 97%;
}

.mobile-checkout-container, .mobile-summary-container {
  height: 100%;
}

.mobile-order-entry-container {
  height: 94%;
}

.mobile-order-entry-container, .mobile-checkout-container, .mobile-summary-container {
  .redirect-to-next-view {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 0.2em solid $primary;
    background-color: $background-light-gray;
    max-height: 5%;
    min-height: 8%;
    position: sticky;

    &.landscape {
      min-height: 13%;
    }
  }
}

.view-mobile-as-modal {
  max-height: max-content !important;

  .modal-content {
    .modal-header {
      display: none;
    }

    .modal-body {
      padding: 0;
    }
  }
}

.first-step-title {
  font-size: large;
  color: black;
  font-weight: bold;
  margin: 1em 0;
}

.first-step-container {
  width: 97%;
  height: 45em;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  right: 0;
  padding-right: 0.8em;

  &.landscape {
    width: 100%;
    padding: 0 10em;
    height: auto;
    left: 0;
  }

  .first-step-selector {
    width: 100%;

    .ordo-searchable-dropdown-container {
      width: 100%;
      margin-bottom: 2em;
    }

    .first-step-selector {
      color: black;
      font-weight: bold;
    }
  }

  .go-to-create-order {
    width: 100%;
  }
}
