.metric-integrations-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .title-and-button {
    display: flex;
  }
  .metrc-integrations-container {
    display: flex;
    flex-direction: column;
  }
}
.flourish-integrations-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .title-and-button {
    display: flex;
  }
  .flourish-integrations-container {
    display: flex;
    flex-direction: column;
  }
}

.nabis-integrations-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .title-and-button {
    display: flex;
  }
  .flourish-integrations-container {
    display: flex;
    flex-direction: column;
  }
}
