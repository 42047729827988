@import '../../purpose/variables';
@import '../common';

.item-image-and-name {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .item-name-and-category {
    margin-left: 1rem;
    display: flex;
    flex-direction: column;
    line-height: 1.3;
    width: 12em;

    .product-name {
      @include text-style;
      font-size: $font-size-sm;
      font-weight: bold;
      max-width: 16em;
      bottom: 3.5em;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }
    .product-category {
      font-size: $font-size-xs;
      white-space: break-spaces;
    }
    .product-sku {
      white-space: nowrap;
      @include text-ellipsis(14em);
    }

    .product-sku-short {
      white-space: nowrap;
      @include text-ellipsis(8em);
    }
  }

  $image-size: 4em;
  .product-image {
    width: $image-size;
    height: $image-size;
    border-radius: 10px;
  }
}

.inventory-list-item-row {
  color: $gray-900;
  border-bottom: 1px solid $table-separator-color;
}

.batches {
  width: 30%;
}

.options {
  padding-left: 0 !important;
  .dropdown {
    .dropdown-toggle {
      color: $primary;
    }
  }
}

.options {
  padding-left: 0 !important;
  .dropdown {
    .dropdown-toggle {
      color: $primary;
    }
  }
}

.column-text {
  @include text-style;
  span {
    font-size: $font-size-sm;
  }

  .product-info {
    display: flex;
    flex-direction: column;

    .bottom-info {
      margin-top: 2.3em;
    }

    .upper-info {
      margin-bottom: .2em;
      margin-top: .7em;
    }
  }
}
