@import '../purpose/variables';
@import '../custom/variables';
@import '../custom/styles';
@import 'common';



.flourish-integration-header {
  display: flex;
  align-items: center;
  margin-bottom: 0.2em;
}

.flourish-sync-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  line-height: 1;

  &__logo {
    .flourish-icon {
      height: 2em;
    }
    margin-right: .5em;
  }

  &__text {
    color: $primary;
    font-size: large;
    font-weight: 700;
  }
}


.flourish-integration-sync-container {
  display: flex;
  justify-content: center;
  color: $gray-900;
  height: 8em;
}
