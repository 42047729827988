@import '../../purpose/variables';
@import '../../custom/variables';
@import '../common';


.mobile-cart {
  height: 100%;
  .cart-header {
    &__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1em;

      &__text {
        font-size: medium;
        font-weight: bold;
        color: $dark-purple;
      }

      &__close-icon {
        color: $pastel-red;
      }
    }

    &__search-item {
      display: flex;
      justify-content: center;
      padding: 0em 1em 0.5em 1em;
    }
  }

  .cart-items-container {
    height: 100%;
    background-color: white;
    .cart-items-table-header {
      height: 1.75em;
      background-color: $primary;
      color: white;
      font-weight: bold;
      display: flex;
      font-size: 0.73rem;

    .product-name {
      width: 34%;
      padding-left: 1.5em;
    }

    .product-pricing {
      padding-left: 1.5em;
      width: 20%;
    }
  }
  .cart-items-list {
    .product-line {
      display: flex;
      align-items: center;
      width: 100%;
      height: 6.25em;
      padding: 1em 1em 1em 0.5em;
      background-color: white;
      border-bottom: 1px solid $light-gray;

      .item-info {
        display: flex;
        align-items: center;
        width: 35%;
        height: 100%;

          .product-image {
            width: 3em;
            height: 3em;
            border-radius: 10px;
          }

          .product-info {
            padding-left: 0.25em;
            line-height: 1.3;
            flex-direction: column;
            color: black;
            font-size: small;

            &__line {
              text-overflow: ellipsis;
              overflow: hidden;
              display: -webkit-box !important;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              white-space: normal;
            }

            &__name {
              color: $dark-purple;
              font-weight: bold;
            }
          }
        }

      .mobile-add-to-cart {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-left: 1.5em;
        width: 65%;
        height: 100%;

        .price-per-size {
          display: flex;
          flex-direction: column;
          align-items: center;
          line-height: 1;
          min-width: 26%;

          .price {
            font-size: small;
            color: black;
          }

          .size {
            font-size: xx-small;
            color: black;
          }
        }

        .product-availability {
          font-size: x-small;
          color: black;
        }

          .cart {
            width: 45%;
            .add-to-cart {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: baseline;
              width: 6em;

              .add-to-cart__input {
                width: 100%;
                display: flex;
                flex-direction: row;
              }

              .number-input {
                padding-left: 0.75em;
                padding-right: 1em;
              }

              input {
                width: 5em;
              }
            }
          }
        }
      }
      .product-line:last-child {
        border-bottom: none;
      }
    }
  }
}
