@import '../../../purpose/variables';
@import '../../../custom/variables';
@import '../../common';

.mobile-order-history-content-body {
  position: absolute;
  width: 100%;
  right: 0;
  padding: 0 .5em;

  .order-history-footer {
    margin-bottom: 1em;
  }
}

.mobile-order-history-orders-list {
  text-align: center;
}

.mobile-order-history-page-filters {
  .account-and-order-filter {

  }

  .order-status-filters {
    display: flex;
    justify-content: space-around;
    margin-bottom: 1em;

    .activities-state-filter {
      margin: 0
    }
  }
}

.mobile-order-history-order-item {
  cursor: pointer;
  background-color: #fff;
  border-radius: 15px;
  margin-bottom: 1em;

  .mobile-order-history-order-item-info {
    display: flex;
    width: 100%;
    justify-content: space-around;
    color: black;
    padding: 0.2em .5em;

    .item-info-field {
      line-height: 1.3;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 22%;

      &.account-info {
        width: 38%;
        align-items: start;
      }

      .title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        font-weight: bold;
      }

      .order-number {
        color: black;
        font-size: x-small;
      }

      .order-status {
        font-weight: bold;
        color: $dark-purple;
      }
    }
  }
}

.mobile-order-expanded-details {
  margin-left: -1em;

  .mobile-order-expanded-details-header {
    display: flex;
    flex-direction: row;
    margin-bottom: 1em;
    margin-top: -3.5em;
    background-color: white;
    color: black;
    justify-content: space-between;

    .exit-icon {
      height: 1.5em;
      margin-right: -0.5em;
      width: 1.5em;
      color: $pastel-red;
    }

    .account-name {
      font-weight: 800;
      font-size: 1.5em;
    }

    .license-number-and-status {
      line-height: 1;
      display: flex;

      .order-status {
        font-weight: bold;
        color: $dark-purple;
        margin-left: 1em;
      }
    }
  }

  .mobile-order-expanded-details-order {
    max-height: 21em;
    overflow-y: scroll;
  }

  .mobile-order-expanded-details-order, .mobile-order-expanded-details-delivery-summary {
    overflow-x: scroll;
  }

  .mobile-order-expanded-details-delivery-summary > * .section-title {
    font-size: small !important;
    margin-bottom: 0 !important;
    color: black !important;
  }

  .mobile-order-expanded-details-delivery-summary > * .delivery-summary-details{
    margin: 0 !important;
  }

  .mobile-order-expanded-details-delivery-summary > * .section{
    line-height: 1.3 !important;

    p {
      margin: 0 !important;
    }
  }

  .mobile-order-expanded-details-delivery-summary > * .delivery-summary-action-buttons {
    justify-content: space-around;

    button {
      height: 2em;

      .button-content {
        width: max-content;
        margin: 0 auto;
        padding: 0 .3em;
      }
    }
  }


  .mobile-order-expanded-details-order, .mobile-order-expanded-details-delivery-summary, .mobile-order-expanded-details-order-payment-summary {
    width: 112%;
    margin-left: -1em;

    &.landscape {
      width: 107%;
      margin-left: -.8em;
    }
  }

  .mobile-order-expanded-details-order {
    &.landscape {
      width: 108%;
    }
  }
}