.upload-logo {
  display: flex;
  flex-direction: column;
  align-items: center;

  label {
    margin-top: 1em;
    width: 50%;
  }
}

.brand-name-input {
  display: flex;
  justify-content: center;
}

.save-brand-button {
  display: flex;
  justify-content: center;
}
