//
// Alert outline variant
//

@mixin alert-outline-variant($border, $color) {
    background: transparent;
    color: $color;
    border-color: $border;

    hr {
        border-top-color: lighten($border, 5%);
    }

    .alert-link {
        color: $color;
    }

    .alert-group-prepend {
        border-right: 1px solid $border;
    }
}
