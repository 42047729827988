@import '../common';
$margin: 3.5em;

@media (max-width: $mobile-breakpoint) {
  .modal-dialog {
    height: 100%;

    .modal-content {
      height: 100%;
    }
  }
}

.mobile-activities-content-body {
  position: absolute;
  margin-left: -($margin);
  width: 100%;
  overflow-x: hidden;

  .activities-mobile-header {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .add-activity-button {
      padding-right: 1em;
      width: 40%;
      height: 1.75em;

      .ordo-button {
        .button-content {
          line-height: 1;
        }
      }
    }

  }

  .activity-page-filters {
    margin-bottom: 1em;
    .activities-state-filters {
      font-weight: bold;
      cursor: pointer;
      padding: .2em 1em;
      width: 100%;
      justify-content: space-between;
      display: flex;

      &:first-child .activities-state-filter {
        margin-left: 0;
      }

      &.checked {
        color: $primary;
        border: 1px solid $primary;
        border-radius: 4px;
      }
    }
  }

  .no-activities {
    display: flex;
    justify-content: center;
  }
}



.activities-section {

  background-color: white;

  &__header {
    height: 2em;
    width: 120%;
    color: white;
    font-weight: bold;
    padding-left: 1em;
    display: flex;
    align-items: center;
    margin-bottom: 1em;

    .name {
      margin-right: 1em;
    }

    &.overdue {
      background-color: $pastel-red;
    }

    &.scheduled {
      background-color: $dark-purple;
    }

    &.completed {
      background-color: $primary;
    }
  }

  .activity-row {
    $padding: calc(#{$margin} / 4);
    width: 100%;
    padding-left: $padding;
    padding-right: $padding;

    &__description {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  &.scheduled {
    .activity-row {
      &:last-child {
        margin-bottom: 0;
      }

      &:not(:last-child) {
        border-bottom: 1px solid $divider-border-color;
      }
    }
  }

  &.overdue {
    .activity-row {

      &:not(:last-child) {
        border-bottom: 1px solid $divider-border-color;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &__header {

        &__info {
          &__subject {
            color: $pastel-red;
          }
          &__checked {
            .ordo-checkbox-container {
              .ordo-checkbox {
                label {
                  span {
                    border-color: $pastel-red;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}



