@mixin _assert-ascending($map, $map-name){}
@mixin _assert-starts-at-zero($grid-points){}
@import '../../purpose/variables';
@import '../../custom/variables';
@import '../common';

.inventory-list {
  margin-top: 2.5em;

  td {
    border-top: none;
    padding: 1.5em 0.5em!important;
  }

  &.order-entry {
    td {
      border-top: none;
      padding: 2.5em 0.5em!important;
    }
  }
}

.table-headers {
  color: $gray-900;

  &.with-border-bottom {

    th {
      font-weight: 700;
      font-size: $font-size-base;
      border-top: none;
      padding: 0.5em !important;
      border-bottom: 1px solid $table-header-separator-color;
    }
  }
}

.inventory-list-card {
  display: flex;
  flex-direction: row;
  margin: 10px 0;
  border-radius: $border-radius;

  .inventory-list-card-content {
    display: flex;
    flex-direction: column;
    padding: 1.5em;
    width: 100%;
  }
}

.no-product-width {
  width: 100%;
}

.no-product-content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
  color: $gray-900;
}




.location-selected-option {
  display: flex;
  flex-direction: column;
  color: $dark;
}

.location-option {
  display: flex;
  flex-direction: column;
  color: gray;
  &:hover {
    color: $dark
  }
}

.location-info {
  display: flex;
  font-size: $font-size-base;
  line-height: 1;

  &__license {
    font-weight: bold;
    margin-right: 1em;
  }

  &__address {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
