@import '../../purpose/variables';
@import '../../custom/variables';

.activities-types-card {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 10px 0;
  border-radius: $border-radius;
  padding: 1em;
}


.activities-types-table-container {
  width: 70%;
  display: flex;
  flex-direction: column;
  margin: 2em;
}

.add-activity-button {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.activities-types-table-headers {
  padding: .5em 2em 0 2em;
  font-weight: bold;
  display: flex;
  flex-direction: row;
}

.activity-type{
  width: 73%;
}

.activity-type-item-name {
  width: 74%;
}

.created, .activity-type-item-created {
  width: 13%;
}

.complete, .activity-type-item-complete {
  width: 12%;
}

.activity-type-item {
  display: flex;
  max-height: 4em;
  flex-direction: row;
  border: 1px solid $primary;
  margin: 1em 0;
  border-radius: 10px;
  padding: 1em 2em;
}
