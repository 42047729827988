// SVG shapes used as separators or illustrations

.shape-container {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 150px;
    z-index: 2;
    transform: translateZ(0);
    overflow: hidden;

    svg {
        fill: $body-bg;
        pointer-events: none;
        vertical-align: baseline;
    }

    &[data-shape-position="top"] {
        top: 0;
        bottom: auto;
        margin-top: -1px;
    }

    &[data-shape-position="bottom"] {
        top: auto;
        bottom: 0;
        margin-bottom: -1px;
    }

    &[data-shape-orientation="inverse"] {
        transform: rotate(180deg);
    }
}

.shape-position-top {
    top: 0;
    margin-top: -1px;
}

.shape-position-bottom {
    bottom: 0;
    margin-bottom: -1px;
}

.shape-orientation-inverse {
    transform: rotate(180deg);
}


// IE fixes

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    .ie-shape-wave-1 {
        height: 504px;
    }
    .ie-shape-wave-3 {
        height: 231px;
    }
    .ie-shape-clouds {
        height: 400px;
    }
}
