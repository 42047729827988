@import '../../purpose/variables';
@import '../../custom/variables';
@import '../common';

.track-order-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 3em;

  & > div {
    margin-bottom: 1em;
    width: 100%;

  }

  .field-label {
    color: $black;
    font-weight: bold;
    margin-bottom: .5em;
  }

  .ordo-searchable-dropdown-container {

    .selected-option {
      .option__title {
        color: $primary;
        font-size: .9em;
      }
      .option__info {
        font-size: .8em;
      }
    }
  }

  &__location {
  }

  &__value-and-invoice {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &__value {
      width: 40%;
      .masked-input-container {
        &__input {
          width: 100%;
          .number-input {
            width: 100%;
          }
        }
      }
    }

    &__invoice {
      display: flex;
      flex-direction: column;
      .field-label {
        margin-bottom: 0;
      }
      .ordo-file-input {
        background-color: $primary;
        border-color: $primary;
        color: #FFF;
        margin: 0;
      }
    }
  }

  &__contact {
  }

  &__delivery-day {
    .ordo-datepicker-container {
      .selected-date-container {
        padding-bottom: 0;
        padding-top: 0;
        height: auto;

        .selected-day {
          color: $primary;
        }
      }
      .no-value-selected {
        padding-top: 0.65em;
      }
      @media (max-width: $mobile-breakpoint) {
        .rdtPicker {
          right: -4em;
        }
      }
    }
  }

  &__payment-method {

  }

}
