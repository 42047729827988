@import '../common';
@import '../../purpose/variables';
@import '../../custom/variables';

.activity-responses-display {
  display: flex;
  width: 100%;
  line-height: 1;

  &.centered {
    line-height: 0.75;
    justify-content: center;
  }

  font-size: 0.9em;

  &__toggle {
    color: $primary;
    cursor: pointer;
  }

}

.activity-responses-display-header {
  display: flex;
  flex-direction: column;
  width: 40em;
  @media (max-width: $mobile-breakpoint) {
    max-width: 80vw;
  }
  padding-left: .5em;

  .title {
    font-size: 1.2em;
    margin-bottom: .5em;
  }
  .subtitle {
    display: flex;
    font-size: .7em;
    color: $gray-700;

    .contacts {
      display: flex;
      align-items: center;
      margin-left: .5em;
      .contacts-icon {
        margin-right: .5em;
      }
    }
  }
}

.activity-responses-display-modal {
  display: flex;
  flex-direction: column;

  .question-display {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 1em;
    padding-left: .5em;

    .question-title {
      color: black;
      font-size: 1.1em;
      margin-bottom: 0.25em;

      &.required {
        &:after {
          content: '*';
          margin-left: 0.2em;
          font-size: 1em;
          color: $primary;
        }
      }
    }

    .text-response {
      color: $dark-purple;
      margin-bottom: .25em;
      margin-left: 1em;
    }

    .datetime-response {
      width: 20em;
      margin-left: 1em;
      display: flex;
      align-items: center;
      color: $dark-purple;

      .datepicker-icon {
        color: $dark-purple;
        margin-left: 0;
        margin-right: .5em;
      }

      .time-display {
        margin-left: 1em;
      }
    }

    .checkbox-response {
      margin-left: 1em;

      &__row {
        display: flex;
        &.centered {
          align-items: center;
        }
        .option-icon {
          cursor: auto;
          margin-right: .5em;
          &.checked {
            color: $dark-purple;
          }
        }
        span {
          color: $dark-purple;
          &.checked {
            font-weight: bold;
          }
        }
        .ordo-checkbox-container {
          .ordo-checkbox {
            &__label {
              cursor: auto;

              &__span {
                &.checked {
                  background-color: $dark-purple;
                }
              }
            }
          }
        }
      }
    }

    .file-upload-response {
      display: flex;
      flex-direction: column;
      margin-left: 1em;

      .uploaded-files {
        display: flex;
        flex-direction: column;
        margin-bottom: .5em;
        .file-row {
          width: min-content;
          color: $primary;
          margin: .25em;
          height: 1em;
          cursor: pointer;

          span {
            @include text-ellipsis(80%);
            white-space: nowrap;
          }
        }
        .file-row:hover {
          text-decoration: underline;
        }
      }
    }

    .product-response {
      margin: .5em 1em;
      border: 2px solid $dark-purple;
      border-radius: 15px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0 0.5em;
      .section {
        width: 20%;
        font-weight: 600;
        padding:0.25em 0.5em;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .info {
        display: flex;
        flex-direction: column;

        .title {
          font-weight: 600;
          line-height: 1;
          text-overflow: ellipsis;
        }
        .subtitle {
          line-height: 1;
          font-size: .70em;
          font-weight: 500;
        }
      }

    }

  }
}