@import '../purpose/variables';
@import '../custom/variables';
@import '../custom/styles';
@import 'common';



.metrc-integration-header {
  display: flex;
  align-items: center;
  margin-bottom: 0.2em;
}

.metrc-integration-header-text {
  font-size: smaller;
}

.metrc-sync-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  line-height: 1;

  &__logo {
    .metrc-icon {
      height: 2em;
    }
    margin-right: .5em;
  }

  &__text {
    color: $primary;
    font-size: large;
    font-weight: 700;
  }
}


.metrc-integration-sync-container {
  display: flex;
  justify-content: center;
  color: $gray-900;
  height: 8em;

  .sync-license {
    align-items: center;
    flex-direction: row;
    display: flex;
    margin-bottom: 1.5em;
    line-height: 1.2;

    .checkbox {
      margin-right: 1em;
    }

    .license-and-address {
      display: flex;
      flex-direction: column;
      margin-left: 1em;
      color: $black;

      .license {
        font-weight: 800;
        font-size: 1.2em;
      }

      .address {
        font-size: 0.8em;
      }
    }
  }

  .step2-container {
    display: flex;
    flex-direction: column;

    .title {
      font-weight: 800;
    }
  }

  .step3-container {
    display: flex;
    align-items: center;
  }

  .metrc-sync-results-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;

    .result {
      display: flex;
      flex-direction: column;

      .title {
        font-weight: 800;
      }
    }

    .error-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      padding-left: 5em;
      justify-content: center;
      height: 0;

      .error {
        color: red;
        width: 100%;
      }
    }
  }
}

.metrc-inventory-sync-modal {
  .modal-sm {
    width: 40em;
    max-width: 40em;
  }
}
