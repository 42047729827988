@import '../purpose/variables';
@import '../custom/variables';
@import 'common';

.order-entry-page {
  height: 100%;
}

.order-entry-page-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.order-entry-page-title {
  color: $black;
  font-size: $font-size-xl;
  font-weight: bold;
}

.order-entry-list-container {
  min-width: 75%;
  height: 100%;
}

.order-entry-list-dropdowns {
  display: flex;
  width: 100%;
  justify-content: space-between;

  .ordo-searchable-dropdown-container {
    width: 49%;
    min-width: 49%;
  }

  .ordo-searchable-dropdown-container > * .option {
    width: 98%;
  }

  .ordo-searchable-dropdown-container > * .option__info__sub-title, .option__info__info {
    font-size: xx-small;
  }

  .ordo-searchable-dropdown-container > * .option__info__info {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 69%;
  }
}

.order-entry-cart-container {
  min-width: 25%;
  margin-left: 0.5em;
  padding: 1em;
  border-radius: $border-radius;
  display: flex;
  flex-direction: column;
  align-items: center;

  .ordo-primary-button {
    padding: 0.2em 0.5em;
  }

  &__content {
    display: flex;
    flex-direction: column;
    margin-top: 1em;

    .item-line {
      display: flex;
      color: $dark;
      border-bottom: solid 1px $gray-400;
      padding-bottom: 2px;
      padding-top: 2px;

      div {
        .add-to-cart {
          height: 2em;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;

          .add-to-cart__input {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
          }

          .btn {
            width: 70%;
            font-size: 0.7rem;
          }
        }
      }

      &__info {
        display: flex;
        flex-direction: column;

        .unit-text {
          color: $primary;
          font-size: $font-size-xs;
          font-weight: bold;
        }

        .product-title {
          font-size: $font-size-sm;
          font-weight: bold;
          max-lines: 2;
          width: 10ch;
        }

        .product-size {
          font-size: $font-size-xs;
        }
      }
    }
  }


  &__header {
    color: $black;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0.5em;

    &__total {
      font-size: $font-size-xl;
    }

    &__sub-total {
      font-size: $font-size-base;
    }
  }

  .complete-order-button {
    width: 100%;
  }
}


td {
  padding: 0.1em 1rem !important;
}

.order-entry-list {
  margin-right: 0.5em;
}

.table-header {
  &.availability {
    display: flex;
    align-items: center;
    min-width: 9em;
    margin-bottom: -0.05em
  }
}

.remove-order {
  color: $gray-700;
  cursor: pointer;
  margin: 0.5em 0 0 0.5em;
}

.coa-info {
  margin-left: 1rem;

  .coa-button {
    border-radius: 7px;
    padding: 1px 6px;
    .btn-inner--text {
      font-size: $font-size-sm;
      font-weight: 400;
      line-height: 1;
    }
  }
}

.order-entry-second-step-container {
  display: flex;
  flex-direction: column;

  @include text-style;

  .search-bar {
    width: 20%;
  }

  .submit-order-button {
    width: 100%;
  }

  &__verify {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1em;

    &__account {
      max-width: 31%;
      margin-right: 1em;

      .ordo-searchable-dropdown-container {
        .option__title {
          line-height: 1;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box !important;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          white-space: normal;
          margin-bottom: .2em;
          margin-top: .3em;
        }
      }
    }

    &__contact {
      width: 23%;
      margin-right: 1em;

      .ordo-searchable-dropdown-container {
        .option__title {
          line-height: 1;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box !important;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          white-space: normal;
          margin-bottom: .2em;
          margin-top: .3em;
        }
      }
    }

    &__distributor {
      width: 23%;
      margin-right: 1em;

      .ordo-searchable-dropdown-container {
        .option__title {
          line-height: 1;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box !important;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          white-space: normal;
          margin-bottom: .2em;
          margin-top: .3em;
        }
      }
    }

    &__delivery-day {
      width: 23%;
    }
  }

  &__body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1em;
    width: 100%;

    &__tables {
      width: 100%;
      min-width: min-content;

      &__cases, &__units {
        background-color: white;
        border-radius: $border-radius;
        width: 100%;

        .ordo-table {
          &__headers {
            &__header-title:last-child{
              padding-right: 0 !important;
              padding-left: 0 !important;
            }
          }
          &__cell:last-child{
            padding-right: 1em !important;
            padding-left: 0 !important;
          }
        }
      }

      &__cases {
        margin-bottom: 1em;

        .selected-product-table {
          color: $black;
          font-weight: 500;
        }

        .add-discount-button {
          &:hover {
            cursor: pointer;
          }
          display: flex;
          justify-content: center;
          align-items: center;

          &__icon {
            &:hover {
              color: $primary-darkest;
            }
          }
        }
      }

      &__units {
        .selected-product-table {
          color: $black;
          font-weight: 500;
        }
      }
    }

    &__order_summary {
      margin-left: 1em;
      @include ordo-card;

      &__data {
        max-width: 17em;
        min-height: 20em;

        &__row {
          max-width: 17em;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-bottom: 0.8em;
          &__line {
            font-weight: bold;
          }
        }

        &__sales_rep {
          margin-bottom: .8em;
        }

        &__payment__method {
          max-width: 20em;

          &__net_term_period {
            margin-top: 1em;
          }
        }
      }

      &__delivery_notes {
        width: 17em;
        display: flex;
        flex-direction: column;
        margin-top: 1em;
        margin-bottom: 2em;
      }
    }
  }
}
.order-entry-second-step_text {
  color: $primary;
  font-weight: bold;
  margin-left: 1em;
}

.add-orders {
  display: flex;
  flex-direction: column;
  min-width: 10em;
  width: 11.2em;
  margin-left: -6em;

  .add-to-cart {
    height: 2em;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    .add-to-cart__input {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    .btn {
      width: 70%;
      font-size: 0.7rem;
    }
  }
}

.add-contact-button {
  display: flex;
  justify-content: space-between;
  align-items: center;

  min-height: 2rem;
  height: 51px;
  padding: 0 1rem;
  background-color: white;

  @include rounded-border;
  @include ordo-shadow;
  @include ordo-card-border;
  @include placeholder-text-style;

  .add-contact-icon {
    color: #cccccc;
  }

  &:hover {
    cursor: pointer;
  }
}

.product-name-container {
  position: relative;
  margin-top: .8em;
}
