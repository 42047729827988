//
// Nav tabs
//


.nav-tabs {
    .nav-item {
        margin-left: $nav-tabs-link-margin-x;
        margin-right: $nav-tabs-link-margin-x;
    }

    .nav-link {
        padding: $nav-tabs-link-padding-y 0;
        border-bottom: $nav-tabs-link-active-border-width solid transparent;
        border-left-width: 0;
        border-right-width: 0;
        border-top-width: 0;

        &:not(.active) {
            color: $gray-600;

            &:hover {
                color: $gray-700;
            }
        }
    }

    .nav-item:first-child {
        margin-left: 0;
    }

    .nav-item:last-child {
        margin-right: 0;
    }

    // Removes the primary color underline from dropdowns in .nav-tabs
    .nav-item.show .nav-link {
        border-color: transparent;
    }

    // Dark nav

    &.nav-dark {
        border-color: rgba($white, .2);

        .nav-link {
            &:not(.active) {
                color: rgba($white, .6);

                &:hover {
                    color: rgba($white, .8);
                }
            }

            &.active {
                color: $white;
                border-color: $white;
            }
        }
    }
}

// .nav-tabs .nav-item .nav-link {
//     padding: .75rem 1rem;
//     transition: color .35s ease, border-color .35s ease;
//     border: 0;
//     border-bottom: 2px solid rgba(255, 255, 255, 0);
//
//     &.active {
//         color: theme-color("primary");
//         border-color: theme-color("primary");
//     }
//
//     &:not(.active) {
//         color: $gray-700;
//
//         &:hover {
//             // color: $gray-700;
//         }
//     }
// }
