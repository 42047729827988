@import '../purpose/variables';
@import '../custom/variables';

.member-card {
  padding: 10px;
  margin-right: 3em;
  width: 20%;
  border-radius: $border-radius;

  &__name {
    font-size: $font-size-base;
    font-weight: bold;
    color: $gray-900;
  }

  &__body {
    padding: 0;

    &__email_and_phone {
      color: $gray-900;
      font-size: ($font-size-base * 0.8);
      font-weight: 500;
    }
  }
}

.pending_invite {
  color: $gray-700
}
