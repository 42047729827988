@import '../../purpose/variables';
@import '../../custom/variables';
@import '../../custom/styles';
@import '../common';

.registration-page-container-width {
  min-width: 40em;
  max-width: 60em;
  width: min-content;
}

.registration-page-wide-container-width {
  min-width: 40em;
  max-width: 65em;
  width: min-content;
}

.login-card {
  border-radius: $border-radius;
}

.login-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .text {
    margin: .5em 0;
  }
}

.registration-button.btn {
  width: 100%;
  .btn-inner--text {
    font-size: 0.84rem;
  }
  line-height: 1.5;
  padding: .5rem 1.25rem;
}
