@import '../../../purpose/variables';
@import '../../../custom/variables';
@import '../../common';

.mobile-summary-container {
  width: 100%;
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  background-color: $background-light-gray;

  .mobile-summary-header {
    height: 35%;

    &.landscape {
      height: 40%;
    }

    .mobile-summary-title {
      display: flex;
      align-items: center;
      font-size: large;
      color: black;
      font-weight: bold;
      padding: 1em 0 1em 1em;
    }

    .mobile-summary-selectors{
      height: 59%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 1em;

      &.landscape {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 21%;

        .ordo-searchable-dropdown-container {
          width: 30%;
          margin-bottom: 0;
        }
      }

      .ordo-searchable-dropdown-container {

        .selected-option {
          width: 97%;
          align-self: center;
        }

        .option__title {
          font-size: 0.70rem;
          max-height: 2em;
          line-height: 1;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box !important;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          white-space: normal;
          margin-bottom: .2em;
          margin-top: .3em;
        }

        .option__info {
          font-size: 0.63rem;
          line-height: 1;
        }
      }

      .select-delivery-date {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-self: center;

        &.landscape {
          width: 40%;
        }

        .date {
          font-size: larger;
          color: $primary;
          margin-left: 1em;
          font-weight: bold;
        }

        .ordo-datepicker-container *.rdtPicker{
          right: -19em;
          top: 2.85em;
        }

        .ordo-datepicker-container *.form-control {
          background-color: $background-light-gray;
          padding: 0 !important;

          svg {
            width: 2em;
            height: 2em;
            margin: 0;
          }
        }

        .selected-date-text {
          color: $primary;
          font-weight: bold;
          line-height: 1.1;
          margin-top: .1em;
          font-size: smaller;
        }
      }
    }

    .mobile-summary-selected-account {
      padding: 0 1.5em;
      color: $gray-900;
      line-height: 1.2;

      &.landscape {
        padding: .5em 1.5em;
      }

      .account-name {
        font-weight: bold;
        font-size: 0.70rem;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box !important;
        -webkit-line-clamp: 1;
      }

      div {
        .location-license {
          font-weight: bold;
          font-size: 0.63rem;
          margin-right: 0.25em;
        }
        .location-address {
          font-size: 0.63rem;
        }
      }
    }
  }

  .divider {
    height: 3%;
    background-color: $primary;
    color: white;
    font-weight: bold;
    display: flex;
    justify-content: center;
    font-size: 0.73rem;

    &.landscape {
      height: 5%;
    }
  }

  .order-summary-container {
    height: 56%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &.landscape {
      height: 42%;
      overflow-y: scroll;
    }

    .order-summary-details {
      width: 75%;
      margin: .5em 0;

      .details-row {
        width: 100%;
        display: flex;
        justify-content: space-between;
        font-size: small;
        line-height: 1.5;

        .title {
          color: $dark-purple;
          font-weight: bold;
        }

        .value {
          color: $gray-900;
        }

        .grand-total {
          font-weight: bold;
        }
      }

      .add-discount-button {
        min-height: 2.5em;
        padding: 0;

        .button-content {
          div {
            margin-bottom: 0 !important;
          }
        }
      }
    }

    .payment-method {
      height: 25%;
      width: 78%;

      &.landscape {
        height: 57%;
      }

      &__net-term-period {
        margin-top: 0.25em;
      }
    }

    .order-notes {
      display: flex;
      flex-direction: column;
      height: 30%;
      width: 78%;
      margin-bottom: 1em;

      &__text {
        color: $primary;
        font-weight: bold;
        margin-left: 0.5em;
      }

      .ordo-text-area {
        padding: 0.5em;
      }
    }
  }
}

.view-mobile-as-modal {
  .modal-content {
    background-color: $background-light-gray;
  }
}
