@import '../../ordo/common';

.verify-delivery-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.verify-order-modal {
  .modal-sm {
    @media (min-width: $mobile-breakpoint) {
      width: 40em;
      max-width: 40em;
    }
  }
}
.verify-order-button {
  margin: .5em 0;

  button {
    min-width: 11em;
    @media (max-width: $mobile-breakpoint) {
      width: 16em;
    }
  }
}

.order-number {
  color: $primary;
}

.log-payment-header {
  display: flex;
  flex-direction: column;
}

.invoice-link {
  color: $primary;
}

.log-payment-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .amount-owed {
    font-size: large;
    color: black;
  }

  .amount-owed-subtitle {
    color: $dark-purple;
    font-weight: bold;
  }

  input {
    border: 2px solid $primary;
  }
}

.label {
  color: $primary;
  font-size: xx-small;
  font-weight: bold;
  position: relative;
  top: 8px;
  padding: 0 0.3em;
  right: 3.3em;
  z-index: 1;
  background-color: white;

  &.with-error {
    color: $red;
  }
}