//
// App buttons
// App store download buttons for Apple Store or Play Store

.btn-app-store {
    background: black;
    color: white;
    position: relative;
    padding-left: 4.5rem;
    padding-right: 1.5rem;

    i, svg {
        font-size: 2.5rem;
        position: absolute;
        left: 1.25rem;
        top: 50%;
        transform: translateY(-50%)
    }

    .btn-inner--text {
        display: block;
        font-size: .75rem;
        line-height: .75rem;
        margin-bottom: .125rem;
    }

    .btn-inner--brand {
        display: block;
        text-align: left;
        font-size: 21px;
        line-height: 21px;
    }

    &:hover {
        background-color: darken(theme-color("primary"), 7%);
        color: color-yiq(theme-color("primary"));
    }
}
