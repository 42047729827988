@import '../purpose/variables';
@import '../custom/variables';
@import 'common';

.ordo-page-tabs-container {
  background-color: #acb5bd;
  border-radius: $border-radius;

  button {
    margin-left: -20px;
    padding-left: 20px;
    padding-right: 20px;
    opacity: 0.5;
  }

  button.current{
    z-index: 1;
    opacity: 1;
  }

  button:first-child {
    margin-left: 0px
  }

  button.btn-light {
    border: none;
    background-color: #acb5bd;
    color: white;
  }
  button.btn-light:hover {
    background-color: #99a1a8;
  }
}

.ordo-page-tabs-container:hover {
  background-color: #99a1a8;
}

@media (max-width: $mobile-breakpoint) {
  .ordo-page-tabs-container {
    margin-bottom: 1em!important;
  }
}
