@import '../../purpose/variables';
@import '../../custom/variables';

.search-bar {
  padding: 1.5em;
  border-radius: $border-radius;
  width: 20%;
  font-size: $font-size-sm !important;
  font-weight: 700;
  color: $gray-900;

  .search-product-input {
    input {
      padding: 1em;
      font-size: $font-size-sm;
      border-radius: $border-radius !important;
    }
  }

  .stock-only-filter {
    margin-left: 1em;
    margin-bottom: 1em;
  }
  .filters {
    margin-bottom: 2em;

    .filter-checkboxes {
      font-size: $font-size-sm;
      margin-top: 0.5em;
      margin-left: 1em;
    }
  }

  .custom-control-input-green:not(:checked)~.custom-control-label::before {
    border-color: $primary !important;
    border: solid 2px;
  }

  .custom-control-input-green:checked~.custom-control-label::before {
    border-color: $primary !important;
    border: solid 2px;
    background-color: $primary;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .unselected-text {
    font-weight: 500;
  }
}
