@import 'common';

.profile-page {
  width: 100%;
}

.user-information-tab {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}


.email-and-gmail-sync {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  width: 20%;
  font-weight: bold;
  font-size: 0.8rem;
  color: $primary;

  .gmail-sync {
    cursor: pointer;

    .gmail-icon {
      width: 1.75em;
      height: auto;
    }

    .disable-gmail-button {
      background-color: $pastel-red;
      border-color: $pastel-red;
      margin-left: 0.5em;

      &:hover {
        background-color: $pastel-red;
        border-color: $pastel-red;
      }

      &:focus {
        background-color: $pastel-red;
        border-color: $pastel-red;
      }
    }
  }
}

.google-auth-modal {
  .modal-header {
    border-bottom: none;
  }
  .google-login-button-container {
    display: flex;
    align-items: center;
    height: 2.5rem;
    padding: 0 0.5rem;
    width: fit-content;
    border: 1px solid transparent;
    box-shadow: 0 1px 1px 1px $brand-logo-border;

    div {
      font-family: Roboto, sans-serif;
      color: #757575;
    }

    .google-icon {
      width: 1.125rem;
      height: 1.125rem;
      margin-right: 1.5rem;
      color: white;
    }
  }
}


.organization-page-tab-container {
  display: flex;
  flex-direction: column;
  width: 100%;

  .new-organization-button {
    margin-bottom: 1em;
    .button-container {
      padding-left: 1em;
      cursor: pointer;
      height: 3em;
      .new-organization-text {
        font-weight: 700;
        color: $gray-800;
        padding: 1em 0;
      }
    }
  }

  .user-organization-cards-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-y: scroll;
    margin-top: 3em;
    width: 100%;
  }
}

@media (max-width: $mobile-breakpoint) {
  .organization-page-tab-container {
    .user-organization-cards-container {
      flex-direction: column;
    }
  }
}