// Colors
$body-bg:       #f8f8fb !default;

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$container-max-widths: map-merge(
  (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1320px
  ),
  $container-max-widths
);

@include _assert-ascending($container-max-widths, "$container-max-widths");

$grid-gutter-width:                 20px !default;

// Main content

$main-content-padding-y:            15px !default;
$main-content-padding-x:            15px !default; // in px to combine with $grid-gutter-width
