@import '../../custom/styles';
@import '../common';

.contact-row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  @include ordo-shadow;
  @include rounded-border;
  @include ordo-card-border;
  padding: .5em 1em;
  margin: .5em 0;
}

.expanded-contact-row {
  display: flex;
  justify-content: space-between;
  width: 30em;
  white-space: nowrap;
  line-height: 1.4;

  .contact-name-and-position {
    display: flex;
    color: black;
    flex-direction: column;
    width: 35%;

    .name {
      font-size: smaller;
      font-weight: bold;
      @include text-ellipsis(90%);

    }

    .position {
      font-size: x-small;
      @include text-ellipsis(90%);
    }
  }

  .contact-email-and-phone {
    font-size: smaller;
    display: flex;
    flex-direction: column;
    color: black;
    width: 40%;

    .email-or-phone {
      display: flex;
      align-items: center;

      span {
        @include text-ellipsis(100%);
      }

      svg {
        margin-right: .5em;
        font-size: 1.1em;
      }
    }
  }

  .contact-edit-and-delete {
    display: flex;
    align-items: center;
    color: $primary;

    .edit-icon {
      margin-right: .5em;
      margin-bottom: .1em;
      cursor: pointer;
    }

    .delete-icon {
      cursor: pointer;
    }
  }

  @media (max-width: $mobile-breakpoint) {
    width: $mobile-row-width;
  }
}
.edit-form-container {
  margin-top: 1em;
}
