@import '../common';
@import '../../purpose/variables';
@import '../../custom/variables';

.activity-complete-wizard {
  .top-section {
    display: flex;
    justify-content: space-between;
    padding-bottom: 1em;
    border-bottom: 1px solid $table-separator-color;
    align-items: center;
    margin-top: -.5em;
    .activity-details {
      display: flex;
      flex-direction: column;
      line-height: 1.2;
      .title {
        color: black;
        font-size: 1.5em;
      }
      .subtitle {
        color: $gray-700;
        font-size: 0.8em;
      }
    }

    .button-side {
      display: flex;
      flex-direction: column;

      .required-note {
        padding-left: 2em;
        color: $primary;
        font-size: 0.7em;
      }
    }

    .ordo-button {
      height: 2em;
    }
  }

  .question-display {
    padding: 1em 0;
    display: flex;
    flex-direction: column;

    .response-count {
      color: $gray-700;
      font-weight: bold;
    }

    .question-title {
      color: black;
      font-size: 1.1em;
      margin-bottom: .5em;
      &.required {
        &:after {
          content: '*';
          margin-left: 0.2em;
          font-size: 1em;
          color: $primary;
        }
      }
    }

    .datetime-response {
      display: flex;
      margin: .5em 0;
      align-items: center;
      .time-picker-container {
        margin-left: 1em;
      }
    }

    .checkbox-response {
      &__row {
        display: flex;
        align-items: center;

        .option-icon {
          margin-right: .5em;
          cursor: pointer;
          &.checked {
            color: $primary;
          }
        }
      }
    }

    .file-upload-response {
      display: flex;
      flex-direction: column;

      .ordo-file-input:not(.disabled) {
        background-color: $primary;
        border-color: $primary;
        color: white;
      }

      .uploaded-files {
        display: flex;
        flex-direction: column;
        .title {
          font-weight: bold;
        }
        .file-row {
          display: flex;
          align-items: center;
          margin: .25em 0 .5em;
          height: 1em;
          cursor: pointer;
          span {
            @include text-ellipsis(80%);
            white-space: nowrap;
          }

          &:before {
            content: '-';
            margin-right: .25em;
            font-weight: bold;
          }

          .icon {
            margin-left: .5em;
            color: $pastel-red;
          }
        }
      }
    }

    .masked-input-container{
      width: 40%;
      &__input {
        width: 100%;
        .number-input {
          width: 100%;
        }
      }
    }
  }

  .bottom-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 1em;

    .pagination-buttons-container {
      margin: 1em 0;

      .pagination-buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: $primary;

        .move-to-page-container {
          cursor: pointer;
          &.disabled {
            cursor: auto;
            color: $gray-700
          }
        }

        & > * {
          margin-right: .5em;

          &.disabled {
            color: $gray-700;
          }
        }

        .current-page-input {
          input {
            height: 1.5em;
            line-height: 1;
            width: 2em;
          }
        }
      }
    }
  }
}