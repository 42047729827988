@import '../purpose/variables';
@import '../ordo/common';

.modal-dialog {
  margin: auto
}

.modal-header {
  .close {
    font-size: 2em;
    padding-bottom: 1em;
    margin-top: -.8em;

    span {
      color: $primary;
    }
  }
}

.modal-sm {
  max-width: 25em;
}

.header-aligned {
  margin-bottom: 0;
}

.no-header {
  .modal-header {
    padding-bottom: 0;
    border-bottom: 0;
  }
  .modal-body {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.modal-footer {
  display: flex;
  padding-top: 0;
  padding-bottom: 2em;
  border-top: 0;
}


.ordo-modal-footer--center {
  justify-content: center;
  .cta-container {
    justify-content: center;
    @media (max-width: $mobile-breakpoint) {
      .btn:not(:last-child){
        margin-bottom: .5em;
      }
    }
  }
}

.ordo-modal-footer--wizard {
  justify-content: center;
  padding: 2em;

  .cta-container {
    display: flex;
    flex-direction: row-reverse;
    .btn:not(:last-child) {
      margin-right: 0;
      margin-left: 0.5rem;
    }
  }
}
