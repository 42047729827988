@import '../common';
@import '../../custom/styles';

.allocations-modal__section-divider {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 98%;
  margin: 2em 0 1em 0;
  height: 2em;

  @include text-style;
  color: $ocean-green;
  font-size: $font-size-sm;
  font-weight: bold;

  span {
    margin-right: 7px;
  }

  hr {
    background-color: $ocean-green;
    width: 100%;
  }
}

.no-licenses {
  width: 100%;
  text-align: center;
}

.allocations-modal-header {
  width: 100%;

  .title {
    color: black;
  }

  .ordo-searchable-dropdown-container {
    margin-bottom: 0-.5em;
  }

  .ordo-searchable-dropdown-container > * .option {
    line-height: 1;

    .option__title {
      font-size: 1em;
    }

    .option__info {
      &__info {
        padding: 0;
      }
    }
  }
}

.allocations-modal__distributor-dropdown {
  margin: .5em 0;
}

$allocation-height: 2.8rem;
.allocations-modal__allocations_section {
  display: flex;
  flex-direction: column;
  padding-left: 0.5em;
  max-height: $allocation-height*3;
  overflow-y: scroll;
  width: 100%;

  &__allocation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-height: $allocation-height;
    color: $black;
    width: 95%;
    margin-bottom: 0.8rem;

    .ordo-input {
      width: 5em;
    }

    &__name {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 78%;
      font-size: $font-size-sm;

      span {
        overflow:hidden;
        display:inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &__update {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 17%;

      label {
        margin: 0;

        input {
          text-align: center;
        }
      }
    }
  }

  .allocation_amount_input {
    .ordo-input__input {
      // Bootstrap styles have higher priority, using important to override this
      // Noticed a bug where any input > 3 numbers would not show up in the input
      padding: 1px !important;
      font-size: $font-size-sm !important;
    }
  }
}

.allocations-modal__accounts-section {
  display: flex;
  flex-direction: column;
  color: $black;
  min-height: $allocation-height;

  &__search_account__select {
    margin-bottom: 2em;
    width: 98%;
    font-size: $font-size-sm;
    line-height: 1;
  }
}

