@import '../../../purpose/variables';
@import '../../../custom/variables';
@import '../../common';

.account-header {
  padding: 1em;
  display: flex;
  height: auto;
}

.account-columns {
  display: flex;
  flex-direction: row;

  .account-info-contacts-and-locations {
    width: 30%;
  }

  .account-activity-section {
    width: 70%;
    margin-left: 1em;
  }

  .activity-divider {
    background-color: $dark-purple;
    margin-left: 1.6em;
    height: 2em;
    width: .5em;
  }

  .account-activity {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1em;

    .activity {
      padding: 1em;

      .activity-title, .total {
        font-weight: 600;
        color: $primary;
        font-size: 0.8rem;

        span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 55ch;
        }

        &.activity-check {
          margin-left: .5em;

          span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 55ch;
          }
        }
      }

      .note-information {
        display: flex;
        align-items: center;
        color: $dark-purple;

        .contacts-icon {
          margin: 0 .5em;
        }
      }

      .account-activity-card {
        width: 100%;
        border: 1px solid $primary;
        display: flex;
        flex-direction: column;
        padding: .5em 1em;
        border-radius: 5px;
        cursor: pointer;

        .content {
          width: 100%;
          display: flex;
          align-items: flex-start;
          .information {
            width: 95%;
            display: flex;
            flex-direction: column;

            .description {
              margin: 1em 0;
              color: black;
            }

            .collapsed {
              line-height: 1.5;
              display: flex;
              flex-direction: row;
              width: 100%;
              align-items: center;
              justify-content: space-between;
            }

            .actions {
              color: $primary;
              display: flex;
              align-items: center;
              justify-content: space-between;

              .icon {
                margin-right: .5em;
                cursor: pointer;
              }
            }
          }

          .icon-container {
            width: 5%;
            margin-top: .5em;

            &.order {
              margin-right: .5em;
              color: #bc9f63
            }

            &.activities, &.external-email-activity {
              svg {
                height: 1em;
                width: 1em;
              }

              color: $activity-color;

              &.incomplete {
                color: $incomplete-activity-color;
              }
            }

            &.notes {
              margin-top: .5em;
              color: $dark-purple;
            }
          }
        }
      }
    }
  }

  .locations-info {
    width: 100%;
    margin: 1em 0;
    display: flex;
    height: auto;
    flex-direction: column;

    .locations-title {
      padding: 1em 1em 0 1em;
      display: flex;
      justify-content: space-between;

      .locations-link-account-button {
        height: 2em;
      }
    }

    .locations {
      display: flex;
      flex-direction: column;

      .location:not(:last-child) {
        border-bottom: 1px solid $gray-700;
      }

      .location {
        width: 100%;
        display: flex;
        flex-direction: row;
        padding: .5em 2em;
        color: black;

        .location-column {
          display: flex;
          flex-direction: column;

          &__address {
            display: inline-block;
            word-break: break-word;
            width: 16em;
          }

          .title {
            color: black;
            font-weight: bold;
          }
          .unlink {
            color: $pastel-red;
            font-weight: bold;
            font-size: .6em;
            cursor: pointer;
          }
        }

        .location-column:last-child {
          margin-left: 1em;
        }
      }
    }
  }

  .account-info {
    width: 100%;
    height: auto;
    padding: 1em;
    display: flex;
    flex-direction: column;

  .values {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 1em 1em 1em;

   input {
     border: 2px solid $primary;
   }

    .account-last-order {
      color: black;
      line-height: 1.2;
      padding-top: .5em;
      min-width: 11em;

      .title {
        font-weight: bold;
      }

      .date {
        font-size: initial;
        font-weight: 100;

        .no-orders {
          color: $gray-700;
        }
      }
    }

    .account-value {
      padding-right: 1em;
      width: 45%;
      height: 5em;

      .label {
        color: $primary;
        font-size: xx-small;
        font-weight: bold;
        position: relative;
        top: 9px;
        padding: 0 0.3em;
        left: 10px;
        z-index: 1;
        background-color: white
      }
    }

    .invalid-input {
      .label {
        color: $danger;
      }

    }

    .input-label {
      width: fit-content;
      max-width: none;
    }
  }
  }

  .account-contacts {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 1em;

    &__header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      color: $dark-purple;
      font-weight: bold;
      font-size: larger;
      padding-left: .2em;
    }

    &__list {
      display: flex;
      flex-direction: column;
      margin-top: 1em;
      padding-left: 1em ;

      .account-contact-row {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        position: relative;
        cursor: pointer;

        &__user-icon {
          color: $black;
        }

        &__user-info {
          display: flex;
          flex-direction: column;
          margin-left: 1em;
          font-size: small;
          color: $primary;

          &__name {
            font-weight: bold;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 30ch;
          }

          &__title {
            margin-top: -0.75em;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 30ch;
          }
        }

        &__contact-tooltip-container {
          display: flex;
          flex-direction: column;
          position: absolute;
          left: 6em;
          top: -1em;
          height: 10em;
          visibility: hidden;
          width: 32em;
          margin: 0;
          z-index: 10;

          &__header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .contact-name-and-title {
              display: flex;
              flex-direction: column;
              color: $dark-purple;

              .name {
                font-size: small;
                font-weight: bold;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 20ch;
              }

              .title {
                margin-left: 0.25em;
                font-size: smaller;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 20ch;
              }
            }

            .contact-edit-and-delete {
              display: flex;
              flex-direction: row;
              color: $primary;

              .edit-icon {
                margin-right: 1em;
              }
            }
          }

          &__fields {
            padding-left: 2em;
            color: $dark;

            .contact-field {
              &__icon {
                margin-right: 1em;
              }
            }
          }
        }


        .contact-row-tooltip-visible {
          visibility: visible;
        }
      }
      .account-contact-row:hover .account-contact-row__contact-tooltip-container{
        visibility: visible;
      }

    }
  }

  .account-payments {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;

    .title-container {
      line-height: 1.2;

      .account-payments-title {
        padding: 1em 1em 0 1em;
        display: flex;
        justify-content: space-between;
        color: $dark-purple;
        font-weight: bold;
        font-size: larger;
      }

      .account-payments-subtitle {
        color: black;
        font-weight: bold;
        padding-left: 1.5em;
        font-size: smaller;
      }
    }

    &__list {
      display: flex;
      flex-direction: column;
      margin-top: 1em;

      &__order-payment:not(:last-child) {
        border-bottom: 1px solid $gray-700;
      }

      &__order-payment {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 1em 1em;

        .amount-owed {
          width: 10em;
         display: flex;
         flex-direction: column;
         align-items: center;

          .debt {
            font-size: large;
            color: black;
          }

          .text {
            font-size: smaller;
            color: $pastel-red;
            font-weight: bold;
          }
        }

        .order-number {
          display: flex;
          flex-direction: column;
          padding-left: 1em;
          line-height: 1;

          .log-payment-button {
            margin-top: .5em;
            height: 2em;
            padding: 0.2em 1em;
            width: 9em;
          }

          .title {
            font-weight: bold;
          }
        }
      }
    }
  }
}

.owners-and-priority {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.priority {
  img {
    height: 2.5em;
    margin: 0;
    width: 2.5em;
  }
}

.owners {
  text-align: end;
}

.owners-title {
  color: $dark-purple;
  font-weight: bold;
}

.account-status-and-name {
  width: 100%;
  display: flex;
  flex-direction: column;
  line-height: 1.2;
  justify-content: center;

  input {
    border: none;
  }

  .account-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
    color: $primary;
    font-size: x-large;
    font-weight: bold;
  }
}

.toggle-order-entry {
  color: $primary;
  font-size: 1em;
  display: flex;
  align-items: center;
  width: 10%;
  margin: 0 2em;
  cursor: pointer;

  svg {
    font-size: 1.8em;
    margin-right: .5em;
  }
  span {
    line-height: 1;
    font-weight: 600;
  }
}

.section-title {
  font-weight: bold;
  font-size: larger;
  color: $dark-purple;
}

.title-and-complete {
  display: flex;
  font-size: 0.8rem;

  .gmail-icon {
    height: 0.85em;
  }
}

.filters-and-options-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.note-form-container {
  margin-bottom: 1em;
}

.copy-icon {
  color: $primary;
  cursor: pointer;
  margin-left: 1em;
}

.expanded-order-container {
  margin-top: 1em;
}

.order-items {
  display: flex;
  flex-direction: column;

  .separator {
    width: auto;
    height: 1px;
    background-color: $primary;
  }
}

.order-item-container-footer {
  border-top: 1px solid $primary;
  padding-top: .5em;
  display: flex;
  justify-content: space-between;

  &.right-align {
    justify-content: end;
  }

  .order-item-container-footer-total {
    font-weight: normal;
    font-size: small;
    width: 19%;
    padding: 0 2.5em;
    cursor: pointer;
    color: $primary;
    text-align: end;

    &.timeline-order-total {
      font-size: x-small;
      padding-left: 1em;
    }
  }
}

.order-item-container-footer-button {
  background-color: $primary;
  color: white;
  font-weight: bold;
  display: flex;
  align-items: center;
  font-size: xx-small;
  justify-content: center;
  padding: 0.1em .5em 0;
  border-radius: 10px;
  height: 2em;
}

.order-item-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: .5em;
  justify-content: space-between;
  width: 100%;

  img {
    width: 2em;
    height: 2em;
    border-radius: 3px;
  }

  .product-info {
    padding: 0 .5em;
    width: 12%;
    min-height: 3em;
    align-items: flex-start;
    color: black;
    display: flex;
    flex-direction: column;
    font-size: smaller;
    line-height: 1.3;

    &.name {
      width: 34%;
    }

    &.product-size {
      width: 22%;
    }

    .title {
      color: $gray-700;
      font-size: smaller;
      font-weight: bold;
    }

    .product-name {
      font-size: x-small;
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }

    .original-price {
      display: flex;
      flex-direction: column;

      .subtitle {
        font-size: xx-small;
      }
    }

    .original-price, .discount {
      color: $dark-purple;
      font-size: x-small;
    }

    .sale-total {
      font-size: x-small;
      color: $primary;
    }
  }
}