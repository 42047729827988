@import 'src/scss/ordo/common';

.rep-assignment-card {
  @include ordo-card;
  padding: 1.2em 1.2em;
  margin-bottom: 1.2em;
  display: flex;
  width: 19%;

  .title {
    font-size: $font-size-base;
    font-weight: bold;
    padding: 0 .5em;
    color: $primary;
    width: 100%;
  }

  &__body {
    width: 85%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__user-section {
      width: 100%;
      margin-bottom: 1em;
    }

    .brands-title {
      width: 100%;
      margin-bottom: .5em;
    }

    &__brands-container{
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      .brands {
        display: flex;
        width: 100%;
        justify-content: space-around;

        .brand {
          img {
            height: 2.5em;
            width: 2.5em;
            border-radius: 5px;
          }
        }
      }

      .no-brands-added {
        width: 100%;
        height: 6em;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .more-brands-text {
        min-height: .6rem;
        margin-top: .5em;
        font-size: $font-size-xs;
      }
    }

    &__owner_title {
      font-size: $font-size-sm;
      font-weight: bold;
      margin-bottom: 0;
      color: $gray-800;
    }

    &__owner_name {
      font-size: $font-size-lg;
    }

    &__assignments-amount {
      font-size: $font-size-base;
    }
  }
};
