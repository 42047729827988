@import '../purpose/variables';
@import '../custom/variables';
@import '../custom/styles';

$brand-logo-border: #e9ecef;
$font-size-tiny: ($font-size-base * .6);
$font-size-extra-small: ($font-size-base * .7);
$font-size-small: ($font-size-base * .8);
$font-size-medium: ($font-size-base * .9);
$font-size-big: ($font-size-base * 1.05);

$sidebar-width: 4em;
$sidebar-min-width: 32px;
$sidebar-margin: 8px;
$sidebar-button-height: 3em;
$page-content-width: 1200px;
$header-height: 3rem;

$light-gray: #adb5bd;

$table-separator-color: $light-gray;
$table-header-separator-color: silver;

$input-width: 6em;

$Z_INDEX_MAX: 1000;

$mobile-breakpoint: 576px;
$mobile-row-width: 25em;

$order-color: #bc9f63;
$activity-color: $ocean-green;
$incomplete-activity-color: #B0BBCA;
$note-color: $dark-purple;

.text-dark {
  color: $dark;
}

.text-primary {
  color: $primary;
  font-size: large;
  font-weight: 700;
}

.ordo-shadow {
  box-shadow: 0 0 3px 1px $brand-logo-border;
}

.ordo-hide {
  display: none;
}

.search-by-account-input {
  margin-right: 2em;
  width: 16%;
  min-width: 12em;
  margin-bottom: 0.4em;
  border-radius: 2em;
  box-shadow: 0 0 0.4em 0.1em $brand-logo-border;
  border: 0.1em solid transparent;
  font-weight: initial;
  font-size: 0.9em;
  padding: 0.54rem 1rem;
  color: gray;
}

.interectable-icon {
  cursor: pointer;
}

.line-height-sm {
  line-height: 1.1;
}

hr {
  border: 0;
  height: 1px;
  background-color: $brand-logo-border;
};

.ordo-card-border {
  border: 1px solid $brand-logo-border;
}

.w-45 {
  width: 45%;
}

.selected {
  border: 2px solid $primary;
}

.no-border {
  border: none
}

 // Titles

.ordo-page-title {
  margin: 0;
  font-size: $font-size-xl;
  font-weight: bold;
}

.ordo-title-and-buttons {
  height: 5em;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: $mobile-breakpoint) {
  .ordo-title-and-buttons {
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
  }

  .ordo-page-title {
    margin-bottom: 1em;
  }
}

.ordo-title-container {
  display: flex;
  flex-direction: column;
  padding: 0 1em;
  align-items: baseline;
}

.ordo-page-sub-title {
  font-size: $font-size-base;
  font-weight: bold;
}

.page-body {
  padding-left: 0.5em;
}

.no-side-margin {
  margin-left: 0;
  margin-right: 0;
}

.ordo-title-align-center {
  width: 100%;
  text-align: center;
}

.selected {
  border: 2px solid $primary;
}

.no-border {
  border: none
}

// Mixins

@mixin remove-focus-border {
  &:focus {
    outline: none;
  }
}

@mixin placeholder-text-style {
  font-size: $font-size-base;
  color: hsl(0, 0%, 50%);
  margin-left: 2px;
  margin-right: 2px;
}

@mixin ordo-shadow {
  box-shadow: 0 0 3px 1px $brand-logo-border;
}

@mixin ordo-card-border {
  border: 1px solid $brand-logo-border;
}

@mixin rounded-border {
  border-radius: $border-radius;
}

@mixin ordo-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.2em 2em;
  background-color: white;
  @include rounded-border;
  @include ordo-shadow;
  @include ordo-card-border;
}

@mixin text-style {
  font-size: $font-size-base;
  white-space: nowrap;
  color: $black;
}

@mixin details-text-style {
  @include text-style;
  font-size: $font-size-sm;
}

@mixin text-ellipsis($maxWidth) {
  max-width: $maxWidth;
  text-overflow: ellipsis;
  overflow: hidden;
}

@mixin blur-page {
  backdrop-filter: blur(2px);
}

@mixin not-allow-select-text {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

@mixin not-allow-clicks {
  pointer-events: none;
  cursor: wait !important;
}

.disable-background {
  @include not-allow-select-text;
  @include not-allow-clicks;
}


// Text Area

.ordo-text-area {
  @include ordo-shadow;
  @include ordo-card-border;
  border-radius: $border-radius;
  outline: none;
  resize: none;
  padding: 1em;
  color: $light-gray;
}

.page-content-body {
  width: 100%;
  padding: 1em;
}

.with-gradient-background {
  background-image: linear-gradient(to right, rgb(216, 225, 237) 0%, rgb(227, 238, 225) 100%);
}

.ordo-logo {
  color: $ocean-green;
  left: 0.5em;
  font-size: 3em;
  position: absolute;
  font-weight: bold;
}
