@import '../purpose/variables';
@import '../custom/variables';
@import '../ordo/common';

.clock-icon {
  color: $primary;
  margin-right: .5em;
}

.time-picker-container {
  display: flex;
  align-items: center;
  cursor: pointer;

  .time-container {
    input {
      border: none;
      width: 5em;
      padding: 0;
      padding-left: 0.5em;
      height: min-content;
    }
  }
}
