@import '../../../purpose/variables';
@import '../../../custom/variables';
@import '../../common';

.mobile-checkout-location-and-date {
  display: flex;
}

.mobile-checkout-title, .mobile-summary-title {
  display: flex;
  align-items: center;
  font-size: large;
  color: black;
  font-weight: bold;
  padding: 1em 0 1em 1em;

  &__text {
    padding-left: 4em;
    width: 92%;

    &.landscape {
      display: flex;
      justify-content: center;
      padding-right: 7em;
    }
  }

  &__icon {
    width: 8%;
  }
}

.mobile-checkout-products-container {
  height: 100%;
  max-height: 75%;
  background-color: white;
  overflow-y: scroll;

  &.landscape {
    height: 49%;
  }

  &__table {
    width: 100%;
    overflow-y: scroll;
    $table-header-height: 1.75em;

    .table-header {
      height: $table-header-height;
      background-color: $primary;
      color: white;
      font-weight: bold;
      display: flex;
      justify-content: space-around;
      font-size: 0.73rem;

      .item-info, .item-qty, .item-price, .item-discount {
        padding-left: 1em;
      }
      .item-info {
        width: 40%;
      }
      .item-qty {
        width: 15%;

      }
      .item-price {
        width: 22.5%;
      }

      .item-discount {
        width: 22.5%;
      }
    }

    .items-list {
      .line-item {
        display: flex;
        align-items: center;
        padding: 0.25em 0.5em;
        background-color: white;
        border-bottom: 1px solid $light-gray;
        height: 6em;

        &__info {
          width: 40%;

          .button-content {
            justify-content: flex-end;
          }

          .line-item-info {
            flex-direction: column;
            color: black;
            font-size: smaller;
            line-height: 1.3;

            .name {
              color: $dark-purple;
              font-weight: bold;
            }

            .info {
              text-overflow: ellipsis;
              overflow: hidden;
              display: -webkit-box !important;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              white-space: normal;
            }
          }
        }

        &__quantity, &__pricing, &__discount {
          width: 20%;
          margin: 0 .5em;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;

          &__input {
            margin-top: .5rem;
            width: 100%;

            input {
              padding: 0 .5em;
              font-size: .8rem !important;
            }
          }

          &__price-per-unit {
            color: black;
            position: absolute;
            top: 4.75em;
            font-size: 0.65rem;
          }
        }

        &__quantity {
          width: 15%;
        }
      }

      .line-item:last-child {
        border-bottom: none;
      }
    }
  }

}

.view-mobile-as-modal {
  .modal-content {
    background-color: $background-light-gray;
  }
}
